input,
textarea {
    @extend %font-headline;
    @extend %button--tertiaire--strocked--raised;
    @extend %font-body-2;
    background-color: var(--white, #fff);
    color: var(--text, #006AA6);
    &:active {
        background-color: inherit;
    }
    &:focus {
        background-color: white;
    }

}
.textarea-wrapper {
    flex: 1;
    align-self: stretch;
    display: flex;
    textarea {
        flex: 1;
        @include media-breakpoint-up('sm') {
            min-height: 210px;
        }
    }
}