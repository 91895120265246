app-notepad-list {

    .notepad-card-list {

        .fake-card .mat-card {
            background-color: $fake-card--background-color;
            color: $fake-card--color;
        }
    }

    button[mat-fab].mat-fab.mat-fab-bottom-right {
        background-color: $accent-c;

        svg {
            path {
                stroke: $default-font-color;
            }
        }
    }
}

app-notepad-data-editor-modal {
    span.mat-form-field-label-wrapper label {
        color: $default-font-color !important;
    }
}
