/* ****************************** MARGINS ****************************** */

/* MARGIN (TOP, BOTTOM, LEFT & RIGHT) */

@mixin m-0 {
    margin: $spacing-0;
}
%m-0 {
    @include m-0;
}
@mixin m-1 {
    margin: $spacing-1;
}
%m-1 {
    @include m-1;
}
@mixin m-2 {
    margin: $spacing-2;
}
%m-2 {
    @include m-2;
}
@mixin m-3 {
    margin: $spacing-3;
}
%m-3 {
    @include m-3;
}
@mixin m-4 {
    margin: $spacing-4;
}
%m-4 {
    @include m-4;
}
@mixin m-5 {
    margin: $spacing-5;
}
%m-5 {
    @include m-5;
}
@mixin m-auto {
    margin: $spacing-auto;
}
%m-auto {
    @include m-auto;
}

/* MARGIN-BLOCK (TOP & BOTTOM) */

@mixin my-0 {
    margin-block: $spacing-0;
}
%my-0 {
    @include my-0;
}
@mixin my-1 {
    margin-block: $spacing-1;
}
%my-1 {
    @include my-1;
}
@mixin my-2 {
    margin-block: $spacing-2;
}
%my-2 {
    @include my-2;
}
@mixin my-3 {
    margin-block: $spacing-3;
}
%my-3 {
    @include my-3;
}
@mixin my-4 {
    margin-block: $spacing-4;
}
%my-4 {
    @include my-4;
}
@mixin my-5 {
    margin-block: $spacing-5;
}
%my-5 {
    @include my-5;
}
@mixin my-auto {
    margin-block: $spacing-auto;
}
%my-auto {
    @include my-auto;
}

/* MARGIN-INLINE (LEFT & RIGHT) */

@mixin mx-0 {
    margin-inline: $spacing-0;
}
%mx-0 {
    @include mx-0;
}
@mixin mx-1 {
    margin-inline: $spacing-1;
}
%mx-1 {
    @include mx-1;
}
@mixin mx-2 {
    margin-inline: $spacing-2;
}
%mx-2 {
    @include mx-2;
}
@mixin mx-2neg {
    margin-inline: $spacing-2neg;
}
%mx-2neg {
    @include mx-2neg;
}
@mixin mx-3 {
    margin-inline: $spacing-3;
}
%mx-3 {
    @include mx-3;
}
@mixin mx-4 {
    margin-inline: $spacing-4;
}
%mx-4 {
    @include mx-4;
}
@mixin mx-5 {
    margin-inline: $spacing-5;
}
%mx-5 {
    @include mx-5;
}
@mixin mx-auto {
    margin-inline: $spacing-auto;
}
%mx-auto {
    @include mx-auto;
}

/* MARGIN-TOP */

@mixin mt-0 {
    margin-top: $spacing-0;
}
%mt-0 {
    @include mt-0;
}
@mixin mt-1 {
    margin-top: $spacing-1;
}
%mt-1 {
    @include mt-1;
}
@mixin mt-2 {
    margin-top: $spacing-2;
}
%mt-2 {
    @include mt-2;
}
@mixin mt-3 {
    margin-top: $spacing-3;
}
%mt-3 {
    @include mt-3;
}
@mixin mt-4 {
    margin-top: $spacing-4;
}
%mt-4 {
    @include mt-4;
}
@mixin mt-5 {
    margin-top: $spacing-5;
}
%mt-5 {
    @include mt-5;
}
@mixin mt-auto {
    margin-top: $spacing-auto;
}
%mt-auto {
    @include mt-auto;
}

/* MARGIN-BOTTOM */

@mixin mb-0 {
    margin-bottom: $spacing-0;
}
%mb-0 {
    @include mb-0;
}
@mixin mb-1 {
    margin-bottom: $spacing-1;
}
%mb-1 {
    @include mb-1;
}
@mixin mb-2 {
    margin-bottom: $spacing-2;
}
%mb-2 {
    @include mb-2;
}
@mixin mb-3 {
    margin-bottom: $spacing-3;
}
%mb-3 {
    @include mb-3;
}
@mixin mb-4 {
    margin-bottom: $spacing-4;
}
%mb-4 {
    @include mb-4;
}
@mixin mb-5 {
    margin-bottom: $spacing-5;
}
%mb-5 {
    @include mb-5;
}
@mixin mb-auto {
    margin-bottom: $spacing-auto;
}
%mb-auto {
    @include mb-auto;
}

/* MARGIN-LEFT */

@mixin ml-0 {
    margin-left: $spacing-0;
}
%ml-0 {
    @include ml-0;
}
@mixin ml-1 {
    margin-left: $spacing-1;
}
%ml-1 {
    @include ml-1;
}
@mixin ml-2 {
    margin-left: $spacing-2;
}
%ml-2 {
    @include ml-2;
}
@mixin ml-3 {
    margin-left: $spacing-3;
}
%ml-3 {
    @include ml-3;
}
@mixin ml-4 {
    margin-left: $spacing-4;
}
%ml-4 {
    @include ml-4;
}
@mixin ml-5 {
    margin-left: $spacing-5;
}
%ml-5 {
    @include ml-5;
}
@mixin ml-auto {
    margin-left: $spacing-auto;
}
%ml-auto {
    @include ml-auto;
}

/* MARGIN-RIGHT */

@mixin mr-0 {
    margin-right: $spacing-0;
}
%mr-0 {
    @include mr-0;
}
@mixin mr-1 {
    margin-right: $spacing-1;
}
%mr-1 {
    @include mr-1;
}
@mixin mr-2 {
    margin-right: $spacing-2;
}
%mr-2 {
    @include mr-2;
}
@mixin mr-3 {
    margin-right: $spacing-3;
}
%mr-3 {
    @include mr-3;
}
@mixin mr-4 {
    margin-right: $spacing-4;
}
%mr-4 {
    @include mr-4;
}
@mixin mr-5 {
    margin-right: $spacing-5;
}
%mr-5 {
    @include mr-5;
}
@mixin mr-auto {
    margin-right: $spacing-auto;
}
%mr-auto {
    @include mr-auto; 
}

/* ****************************** PADDING ****************************** */

/* PADDING (TOP, BOTTOM, LEFT & RIGHT) */

@mixin p-0 {
    padding: $spacing-0;
}
%p-0 {
    @include p-0;
}
@mixin p-1 {
    padding: $spacing-1;
}
%p-1 {
    @include p-1;
}
@mixin p-2 {
    padding: $spacing-2;
}
%p-2 {
    @include p-2;
}
@mixin p-3 {
    padding: $spacing-3;
}
%p-3 {
    @include p-3;
}
@mixin p-4 {
    padding: $spacing-4;
}
%p-4 {
    @include p-4;
}
@mixin p-5 {
    padding: $spacing-5;
}
%p-5 {
    @include p-5;
}

/* PADDING-BLOCK (TOP & BOTTOM) */

@mixin py-0 {
    padding-block: $spacing-0;
}
%py-0 {
    @include py-0;
}
@mixin py-1 {
    padding-block: $spacing-1;
}
%py-1 {
    @include py-1;
}
@mixin py-2 {
    padding-block: $spacing-2;
}
%py-2 {
    @include py-2;
}
@mixin py-3 {
    padding-block: $spacing-3;
}
%py-3 {
    @include py-3;
}
@mixin py-4 {
    padding-block: $spacing-4;
}
%py-4 {
    @include py-4;
}
@mixin py-5 {
    padding-block: $spacing-5;
}
%py-5 {
    @include py-5;
}

/* PADDING-INLINE (LEFT & RIGHT) */

@mixin px-0 {
    padding-bottom: $spacing-0;
}
%px-0 {
    @include px-0;
}
@mixin px-1 {
    padding-inline: $spacing-1;
}
%px-1 {
    @include px-1;
}
@mixin px-2 {
    padding-inline: $spacing-2;
}
%px-2 {
    @include px-2;
}
@mixin px-3 {
    padding-inline: $spacing-3;
}
%px-3 {
    @include px-3;
}
@mixin px-4 {
    padding-inline: $spacing-4;
}
%px-4 {
    @include px-4;
}
@mixin px-5 {
    padding-inline: $spacing-5;
}
%px-5 {
    @include px-5;
}

/* PADDING-TOP */
@mixin pt-0 {
    padding-top: $spacing-0;
}
%pt-0 {
    @include pt-0;
}
@mixin pt-1 {
    padding-top: $spacing-1;
}
%pt-1 {
    @include pt-1;
}
@mixin pt-2 {
    padding-top: $spacing-2;
}
%pt-2 {
    @include pt-2;
}
@mixin pt-3 {
    padding-top: $spacing-3;
}
%pt-3 {
    @include pt-3;
}
@mixin pt-4 {
    padding-top: $spacing-4;
}
%pt-4 {
    @include pt-4;
}
@mixin pt-5 {
    padding-top: $spacing-5;
}
%pt-5 {
    @include pt-5;
}

/* PADDING-BOTTOM */

@mixin pb-0 {
    padding-bottom: $spacing-0;
}
%pb-0 {
    @include pb-0;
}
@mixin pb-1 {
    padding-bottom: $spacing-1;
}
%pb-1 {
    @include pb-1;
}
@mixin pb-2 {
    padding-bottom: $spacing-2;
}
%pb-2 {
    @include pb-2;
}
@mixin pb-3 {
    padding-bottom: $spacing-3;
}
%pb-3 {
    @include pb-3;
}
@mixin pb-4 {
    padding-bottom: $spacing-4;
}
%pb-4 {
    @include pb-4;
}
@mixin pb-5 {
    padding-bottom: $spacing-5;
}
%pb-5 {
    @include pb-5;
}


/* PADDING-LEFT */

@mixin pl-0 {
    padding-left: $spacing-0;
}
%pl-0 {
    @include pl-0;
}
@mixin pl-1 {
    padding-left: $spacing-1;
}
%pl-1 {
    @include pl-1;
}
@mixin pl-2 {
    padding-left: $spacing-2;
}
%pl-2 {
    @include pl-2;
}
@mixin pl-3 {
    padding-left: $spacing-3;
}
%pl-3 {
    @include pl-3;
}
@mixin pl-4 {
    padding-left: $spacing-4;
}
%pl-4 {
    @include pl-4;
}
@mixin pl-5 {
    padding-left: $spacing-5;
}
%pl-5 {
    @include pl-5;
}

/* PADDING-RIGHT */

@mixin pr-0 {
    padding-left: $spacing-0;
}
%pr-0 {
    @include pr-0;
}
@mixin pr-1 {
    padding-left: $spacing-1;
}
%pr-1 {
    @include pr-1;
}
@mixin pr-2 {
    padding-left: $spacing-2;
}
%pr-2 {
    @include pr-2;
}
@mixin pr-3 {
    padding-left: $spacing-3;
}
%pr-3 {
    @include pr-3;
}
@mixin pr-4 {
    padding-left: $spacing-4;
}
%pr-4 {
    @include pr-4;
}
@mixin pr-5 {
    padding-left: $spacing-5;
}
%pr-5 {
    @include pl-5;
}

/* ****************************** GAP ****************************** */

@mixin g-0 {
    gap: $spacing-0; // 0
}
%g-0 {
    @include g-0;
}
@mixin g-1 {
    gap: $spacing-1; // 5px to 10px
}
%g-1 {
    @include g-1;
}
@mixin g-2 {
    gap: $spacing-2; // 10px to 20px
}
%g-2 {
    @include g-2;
}
@mixin g-3 {
    gap: $spacing-3; // 20px to 40px
}
%g-3 {
    @include g-3;
}
@mixin g-4 {
    gap: $spacing-4;  // 40px to 80px
}
%g-4 {
    @include g-4;
}
@mixin g-5 {
    gap: $spacing-5; // 80px to 160px
}
%g-5 {
    @include g-5;
}