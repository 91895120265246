@use '@angular/material' as mat;

%reset-card { // mat-card

    margin: 0;
    padding: 0;
    &:not([class*=mat-elevation-z]){
        box-shadow: inherit;
    }
    .mat-card-avatar {
        height: inherit;
        width: inherit;
        border-radius: inherit;
        flex-shrink: inherit;
        object-fit: inherit;
    }
    .mat-card-header-text {

    }
    .mat-card-header .mat-card-title {
        margin-bottom: 0;
    }

}

%card--max-width {
    max-width: 800px;
}

%mat-card { // modèle général
    @extend %reset-card;
    flex: 1;
    @extend %r-2;

    mat-card-header {
        // Layout
        display: flex;
        min-height: 50px;
        @extend %rt-2;
        // Style

        .mat-card-header-text {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    mat-card-content {
        // Layout
        @extend %p-2;
        @extend %pb-3;
        @extend %rb-2;
    }
}

// Figma CARD QUATERNAIRE
/* ... */
%card--quaternaire {
    @extend %mat-card;
    &:not([class*=mat-elevation-z]){ // compense l'utilisation hors HTML
        @include mat.elevation(5);
    }

    mat-card-header {
        // Layout
        @extend %p-2;
        // Style
        color: var(--white, #fff);
        background: var(--quaternaireborder, #006AA6);
    }
    mat-card-content {
        // Style
        background: var(--quaternairebackground,#002940);
    }
}