// --------------------------------------------------
// MIXINS
// --------------------------------------------------

@mixin app-image-zoning {

    .activities-card {

        &.two-columns-layout {

            #container-for-answers-selected {
                flex-direction: column;

                > div {
                    position: relative;
                    flex: auto;
                    width: 100%;
                    height: 40px;
                    background: rgba(15, 169, 255, 0.1);
                    margin-bottom: 5px;

                    .answer-position {
                        position: absolute;
                        display: flex;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        .mat-card-content {
            flex-basis: 0 !important;
            padding-bottom: 20px;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .available-answers {
                display: flex;
                flex-direction: column;
                justify-content: stretch;
                align-items: center;
                max-width: unset;
                max-height: unset;
                padding: 0;
                margin: 0 !important;
                height: calc( 70vh - 140px);

                .container {
                    aspect-ratio: auto; // to avoid aspect-ratio: 16/9 inherit by default;
                    position: relative;
                    overflow: visible;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    width: auto;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    border: 2px solid var(--primaryborder, #4B001C);
                    border-radius: 7px;

                    &.false-answer {
                        border: 2px solid var(--primaryborder, #4B001C);
                    }

                    &.true-answer {
                        border: 2px solid var(--was-correct,#01844D);
                    }

                    > div {
                        width: 100%;
                        height: 100%;

                        img {
                            overflow: hidden;
                            width: auto;
                            height: 100%;
                            border: none;
                            border-radius: 3px;
                            box-shadow: none;
                            filter: none;
                        }
                    }

                    app-zone-generator {
                        position: absolute;
                        display: flex;
                        width: 100%;
                        height: 100%;
                        opacity: 0.5;

                        .generated-column {
                            width: 100%;
                            padding: 1px;

                            .generated-row-element {
                                width: 100%;
                                height: 25%; // Default value for 4 * 4 element in matrix
                                padding: 1px;
                                .colored-zone {
                                    width: 100%;
                                    height: 100%;
                                    background-color: transparent;
                                    border-radius: 3px;

                                    &.selected-true {
                                        background-color: var(--was-correct,#01844D);
                                    }

                                    &:hover {
                                        cursor: pointer;
                                    }

                                    &:active {
                                        background-color: #3A3A3A !important;
                                        opacity: 0.5;
                                    }
                                }
                            }
                        }
                    }
                }
                audio {
                    height: 0;
                }
            }
        }
    }
}

// --------------------------------------------------
// STYLE
// --------------------------------------------------

app-image-zoning {
    @include app-image-zoning;
}