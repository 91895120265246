> mat-card-content.mat-card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    gap: 20px;
    padding: 0 var(--padding-lateral-viewport);

    // overflow: auto; // croppe les coins des réponses

    @include media-breakpoint-up('sm') {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;


        // PROVISOIRE
        > app-consignes { // PROVISOIRE
            flex: 5;
            align-items: center;
            max-width: 90vh;

            > .consigne-img, > div {
                flex: 1;
                display: flex;


                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            > .consigne-img {
                flex-direction: column;
            }
        }
        > app-consignes + div,
        { // PROVISOIRE
            flex: 4;
            margin-inline: inherit;
            margin-bottom: auto;
            min-width: 40%;
            margin: 2% auto auto auto !important; // **1
        }


    }

    // background-color: #7D818C;
}

mat-card-footer.mat-card-footer {
    @include media-breakpoint-up('sm') {
        // background-color: aqua;
        flex: none;
        margin: 0;
        position: relative;
        padding: 0 clamp(10px, 0.76vw + 5.45px, 20px);

        button {
            //position: absolute;
            //bottom: 2vh;
            //// right: calc(37% - 35vh);
            //right: 50%;
            //transform: translateX(50%);
        }
    }
}
app-what-is-missing-audio &.mat-card { // TODO Conception HTML orginale !! css à déplacer ou modifier HTML
    .mat-card-footer {
        // grid-area: mat-card-footer;

        flex-direction: column;
    }
    @include media-breakpoint-up('sm') {

    //    mat-card.mat-card {
    //        display: grid;
    //        grid-template-columns: 1fr 1fr;
    //        grid-template-rows: 0.3fr 1.7fr;
    //        gap: 0px 0px;
    //        grid-auto-flow: row;
    //        grid-template-areas:
    //"mat-card-header mat-card-header"
    //"mat-card-content mat-card-footer";
    //    }

        .mat-card-content {
            // grid-area: mat-card-content;
        }

        .mat-card-header {
            // grid-area: mat-card-header;
        }

        .mat-card-footer {
            // grid-area: mat-card-footer;

            //flex-direction: column;
        }

    }
}