// version: 0.0.0

/*
*
*               fxflex deprecated
*       Remplacez par les placeholders suivant
*
 */

// *******************  Search bem-remove-fx in HTML and replace ***********************
// PlaceHolder Pattern
//%<Flex><Direction><AlignContent><AlignItem>
// ************************************************************************************

// display Flex
%fxDisplayFlex {
    display: flex;
}
.fxDisplayFlex { @extend %fxDisplayFlex; }

      /* /!\ PAS de Height 100% */                              /* /!\ PAS de Width 100% */
/*************************************** /      ||      / ***************************************/
/*                    ^                  /      ||      /                                       */
/*                    ^                  /      ||      /                                       */
/*                 column                /      ||      /  <<              row               >> */
/*                   v                   /      ||      /                                       */
/*                   v                   /      ||      /                                       */
/*************************************** /      ||      / ***************************************/
// fxFlex
%fxFlex {
    flex: 1;
}
.fxNoneColumnCenterNone { @extend %fxFlex; }

/****************************************/
/*A*/
/*B*/
/*C*/
/* */
/* */
/****************************************/
// fxFlex="none" fxLayout="column" fxLayoutAlign="center none"
%fxNoneColumnCenterNone {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    place-content: stretch center;
    align-items: stretch;
}
.fxNoneColumnCenterNone { @extend %fxNoneColumnCenterNone; }

/****************************************/
/*                  A                   */
/*                  B                   */
/*                  C                   */
/*                                      */
/*                                      */
/****************************************/
// fxFlex + fxLayout="column"
%fxFlexColumn {
    display: flex;
    flex-direction: column;
}
.fxFlexColumn { @extend %fxFlexColumn; }


/****************************************/
/*                                      */
/*      A       B       C       D       */
/*                                      */
/****************************************/
// fxLayout="row" fxLayoutAlign="space-around center"
%fxRowSpaceAroundCenter {
    height: 100%;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    place-content: center space-around;
}
.fxRowSpaceAroundCenter { @extend %fxRowSpaceAroundCenter; }

/****************************************/
/*                                      */
/*A           B            C           D*/
/*                                      */
/****************************************/
// fxLayout="row" fxLayoutAlign="space-beetween center"
%fxRowSpaceBetweenCenter {
    height: 100%;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;

    place-content: center space-between;
    align-items: center;
}
.fxRowSpaceAroundCenter { @extend %fxRowSpaceBetweenCenter; }

/****************************************/
/*                                      */
/*ABCD                                  */
/*                                      */
/****************************************/

%fxRowStartCenter {
    height: 100%;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;

    place-content: center flex-start;
    align-items: center;
}
.fxRowStartCenter { @extend %fxRowStartCenter; }

/****************************************/
/*A           B            C           D*/
/*A           B            C           D*/
/*A           B            C           D*/
/****************************************/

%fxRowSpaceBetweenStretch {
    height: 100%;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;

    place-content: stretch space-between;
    align-items: stretch;
    max-height: 100%;
}
.fxRowSpaceBetweenStretch { @extend %fxRowSpaceBetweenStretch; }




/******************/
/*AAAAAAAAAAAAAAAA*/
/*BBBBBBBBBBBBBBBB*/
/*CCCCCCCCCCCCCCCC*/
/*                */
/*                */
/******************/

%fxColStartStretch {
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;

    place-content: stretch flex-start;
    align-items: stretch;
    max-width: 100%;
}
.fxColStartStretch { @extend %fxColStartStretch; }