@use 'sass:math';

/* ******************************* PATH FUNCTIONS ****************************************/

@function asset($type, $file, $module: null) {
    @return url($asset-base-path + '/' + $type + $module + '/' + $file);
}

@function image($file, $module:'') {
    @return asset('images', $file, $module)
}

@function icon($file, $module:'') {
    @return asset('icons', $file, $module);
}

@function font($file, $module:'') {
    @return asset('fonts', $file, $module);
}

/* ******************************* NUMERIC FUNCTIONS ****************************************/

@function round-number($number, $decimals: 2) {
    $n: 1;
    @if $decimals > 0 {
        @for $i from 1 through $decimals {
            $n: $n * 10;
        }
    }
    @return math.div(math.round($number * $n), $n);
}

@function convert-px-to-rem($px) {
    $rems: math.div($px, $root-em) * 1rem;
    @return $rems;
}

@function set-fluid-typo($min-size, $max-size, $min-breakpoint: 600px, $max-breakpoint: 1920px, $unit: vw) {
    $slope: math.div($max-size - $min-size, $max-breakpoint - $min-breakpoint);
    $slope-to-unit: round-number($slope * 100);
    $intercept-rem: round-number(convert-px-to-rem($min-size - $slope * $min-breakpoint));
    $min-size-rem: round-number(convert-px-to-rem($min-size));
    $max-size-rem: round-number(convert-px-to-rem($max-size));
    @return clamp(#{$min-size-rem}, #{$slope-to-unit}#{$unit} + #{$intercept-rem}, #{$max-size-rem});
}