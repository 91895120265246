@use '@angular/material' as mat;

#register {
    width: 100%;
    overflow: auto;

    #register-form-wrapper {
        flex: 1 0 auto;
        padding: 32px;

        @include media-breakpoint('xs') {
            padding: 16px;
        }
    }


}

#register-form {
    padding: 32px;
    background: #FFFFFF;
    text-align: center;
    @include mat.elevation(16);

    @include media-breakpoint('xs') {
        padding: 24px;
        width: 100%;
    }

    .logo {
        width: 128px;
        margin: 0 auto;
    }

    .title {
        font-size: 20px;
        margin: 16px 0 32px 0;
    }

    form {
        width: 100%;
        text-align: left;

        mat-form-field {
            width: 100%;
        }

        mat-checkbox {
            margin: 0;
        }

        .terms {
            margin: 16px 0 32px 0;

            a {
                font-size: 16px;
                margin-left: 4px;
            }
        }

        .submit-button {
            width: 220px;
            margin: 16px auto;
            display: block;

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
                background-color: map_get($accent, 500);
                color: map_get($primary, 500);
            }

            @include media-breakpoint('xs') {
                width: 90%;
            }
        }
    }

    .register {
        margin: 32px auto 24px auto;
        font-weight: 500;

        .text {
            margin-right: 8px;
        }
    }

    .separator {
        font-size: 15px;
        font-weight: 600;
        margin: 24px auto;
        position: relative;
        overflow: hidden;
        width: 100px;
        color: rgba(0, 0, 0, 0.54);

        .text {
            display: inline-flex;
            position: relative;
            padding: 0 8px;
            z-index: 9999;

            &:before, &:after {
                content: '';
                display: block;
                width: 30px;
                position: absolute;
                top: 10px;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
            }

            &:before {
                right: 100%;
            }

            &:after {
                left: 100%;
            }
        }
    }

    button {

        &.google,
        &.facebook {
            width: 192px;
            text-transform: none;
            color: #FFFFFF;
            font-size: 13px;
        }

        @include media-breakpoint('xs') {
            width: 80%;
        }

        &.google {
            background-color: #D73D32;
            margin-bottom: 8px;
        }

        &.facebook {
            background-color: rgb(63, 92, 154);
        }
    }

    #bloc-you-are {
        h2,
        label {
            color: #3C3C3C;
            font-family: Raleway;
        }

        .bloc-title {
            h2 {
                color: #3B4750;
                font-family: Raleway;
                font-size: 24px;
                font-weight: 500;
                line-height: 29px;
            }

            label {
                font-size: 36px;
                font-weight: bold;
                line-height: 42px;
                text-align: center;
            }
        }

        .bloc-pastille-register {
            label {
                font-size: 14px;
                font-weight: bold;
                line-height: 16px;
                height: 50px;
                margin: 0;
            }

            .pastille {
                border-radius: 50%;
                height: 160px;
                width: 160px;
                cursor: pointer;
            }
        }
    }
}

fuse-profile-about {
    #about {
        max-width: 1200px;

        .about-content {

            .general {

                .location {

                    mat-icon {
                        line-height: 13px !important;
                    }
                }
            }

            .work {

                .job {

                    .company {
                        padding: 0 16px 0 0;
                        font-weight: 500;
                    }

                    .date {
                        color: rgba(0, 0, 0, 0.54);
                    }
                }
            }
        }

        .about-sidebar {
            padding-left: 32px;

            @include media-breakpoint('sm') {
                padding: 8px
            }

            .friends {

                .content {

                    .friend {
                        padding: 4px;
                    }
                }
            }

            .groups {

                .content {

                    .group {
                        margin-bottom: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .logo {
                            border: 1px solid rgba(0, 0, 0, 0.12);
                            margin-right: 16px;
                        }

                        .name {
                            font-weight: 500;
                            font-size: 15px;
                        }

                        .category,
                        .members {
                            color: rgba(0, 0, 0, 0.54);
                        }

                        .members {
                            margin-top: 16px;
                        }
                    }
                }
            }
        }
    }

    // Profile boxes
    .profile-box {
        margin-bottom: 16px;
        @include mat.elevation(2);

        header {
            padding: 16px;

            .title {
                font-size: 17px;
            }

            .more {
                cursor: pointer;
            }
        }

        .content {
            padding: 16px;
            background-color: #FFF;

            .avatar {
                width: 60px;
                height: 60px;
            }
        }

        footer {
            padding: 8px;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
            background-color: rgba(0, 0, 0, 0.06);
        }

        &.info-box {

            .info-line {
                margin-bottom: 24px;

                .title {
                    font-size: 15px;
                    font-weight: 500;
                    padding-bottom: 4px;
                }

                .info {

                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

app-upload-file-generic {
    .ngx-file-drop__content {
        flex-direction: column;
    }

    .error {
        color: #e62716;
    }
}

fuse-profile-about .profile-box .content {
    word-break: break-all;
}

fuse-login mat-spinner {
    margin: auto;
}
