app-snackbar-recall-button {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 1;

    button {
        background: #FBAC35;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4.9539px;
        color: white;

        width: 50px;
        height: 50px;

        font-size: 36px;
        font-weight: 900;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: center;
    }
}