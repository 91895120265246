.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
    // display: block !important; // because default css and fuse helper display: none;
}

.hide {
    display: none !important;
}