@use 'node_modules/@angular/material/index' as mat;
// @import 'activities__answers-status';

&.mat-card {
    @extend %reset-card;
    flex: 1;
    display: flex;
    flex-direction: column;

    // @extend %p-2;
    // padding: 0 var(--padding-lateral-viewport);
    @extend %g-3;

    overflow: auto;

    color: var(--text, #006AA6); // à passer dans la config Mat

    // box-shadow: inset 0 0 0px 10px #00bbff; // debug
    //border: 5px solid #0065fd;
}