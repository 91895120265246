//  app-home-page
//      div fxflex="" fxlayout="column" fxlayoutalign="start center"
//          div.banner
//          div.title
//          div.content
//          div.content-cards
//
//              div.p-16 fxflex.gt-sm="50" lt-sm="100" gt-sm="50"

app-home-page .title {
    color: $app-home-page__card-header--color;
}

app-home-page .content p {
    color: $app-home-page__card-header--color;
    font-size: $app-home-page__content__paragraph--font-size;
    line-height: $app-home-page__content__paragraph--line-height;
}

app-home-page .content-cards mat-card.mat-card {
    height: 100%;
    min-height: 250px;
}

app-home-page .content-cards mat-card.mat-card .mat-card-header {
    background-color: $primary-c;
}

app-home-page .content-cards mat-card.mat-card .mat-card-header .mat-card-title-text,
app-home-page .content-cards mat-card.mat-card .mat-card-header .mat-card-title {
    color: $app-home-page__card-header--color;
}

app-home-page .content-cards mat-card.mat-card .wrapper mat-card-content.mat-card-content {
    color: $default-font-color;
    font-size: $app-home-page__card-content--font-size;
    font-weight: $app-home-page__card-content--font-weight;
    line-height: $app-home-page__card-content--line-height;
}

app-home-page .content-cards > div mat-card.mat-card mat-card-actions button {
    @extend %button--accent;
}