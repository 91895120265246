fuse-corpus-file-list {

    button.mat-icon-button {
        @extend %button--only-icon;
        color: var(--bje-rouge);

        .mat-icon {
            color: var(--bje-rouge);
        }
    }

    button.flag {
        &.active {
            .mat-icon {
                path {
                    color: var(--bje-rouge) !important;
                    fill: var(--bje-rouge) !important;
                }
            }
        }
    }

    table tr td:first-child, table tr.mat-row.selected td:first-child {
        mat-icon {
            color: $default-font-color !important;
        }
    }
}