// Grouping theme modules

/*
        Utilisé par humanum, inclusive, cycle1necanope, cycle2et3necanope, cycle4necanope
 */
// @import "activities/_activities";
// @import '../../default/modules/scss/_activities';
/* ************************************************** */

// @import "activities/_player-components";
@import "account-management"; // lms et app
@import "app-home-page";
@import "assignation";
@import "authentication-account-management";
@import "corpus";
@import "dashboard";
@import "groups-management";
@import "app-graph-group-management";
@import "graph-mathia";
@import "gamecode";
@import "mindmap";
@import "notepad";
@import "faq";
//@import "_licensing";
//@import "_graph";
//@import "_configuration";