@use '@angular/material' as mat;
// Pastille use in:
//* groups-management
//* research sheet
//* account-management
//* activities
//* app-dash-widget

 .pastille {
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
  background-color: mat.get-color-from-palette($primary);
  width: 24px;
  height: 24px;

  &.selected{
    box-sizing: border-box;
    border: 3px solid #FFFFFF;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.77);
  }

  &.pastille-icon {
    display: flex;
    place-content: center;
    align-items: center;
    path {
      fill: #fff;
    }
  }

  .mat-icon {
    path {
      fill: #fff;
    }
  }

  form & { // TODO mv to specific default-module target
    height: 53px;
    width: 53px;
  }

  lms-research-sheet-display-section & {
    //rm .section-details
    //rm .pastille-comment
    //= .section-details .pastille
    width: 10px;
    height: 10px;
    background: mat.get-color-from-palette($primary);
  }
  lms-research-sheet-display-add-section & {
    //= lms-research-sheet-display-add-section .pastille
    //rm .section-pastille
    box-shadow: 0px 0px 10px rgba(255, 120, 125, 0.3);
    min-width: 40px;
    min-height: 40px;
    color: white;
  }
  app-research-sheet-list & {
    //= app-research-sheet-list .bloc-pastille-register
    display: inline-block;
    height: 16px;
    width: 16px;
    background-color: #DFB900;
  }

  fuse-register & {
    //= fuse-register .bloc-pastille-register .pastille
    height: 160px;
    width: 160px;
  }

  fuse-app-lessons & {
    //= fuse-app-lessons .pastille
    height: 16px;
    width: 16px;
    background-color: #DFB900;
  }

  app-dash-widget & {
    //= app-dash-widget .pastille
    width: 23px;
    height: 23px;
  }

}
