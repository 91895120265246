app-banner-info {
    display: flex;
    margin: 0 0 16px 0;
    border-radius: 5px;
    background-color: var(--faux-blanc);

    .groups-inline-modal-learner & {
        display: none;
    }

    fuse-app-lessons-list & { // ajuste le marges en fonction de la page parent TODO standardiser les parents
        margin: 8px 8px 16px;
    }
    app-corpus-root & { // ajuste le marges en fonction de la page parent TODO standardiser les parents
        margin: 0 15px 17px 14px;
    }
    app-gamecode-list & { // ajuste le marges en fonction de la page parent TODO standardiser les parents
        margin: 16px 15px 15px 25px;
    }

    mat-toolbar.mat-toolbar {

        h3 {
            line-height: $banner-info__mat-toolbar--line-height;
        }

        p {
            margin: 0;
            font-weight: $banner-info__mat-toolbar__paragraph--font-weight;
            line-height: $banner-info__mat-toolbar--line-height;
            white-space: break-spaces;
        }
    }

    .mat-toolbar-single-row {
        background: transparent;
        padding: 18px 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
    }
}
