app-collection {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 32px;
    gap: 32px 42px;

    .header{
        display: flex;
        flex: 1;

        button {

            .mat-button-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }
    }

    h2{
        margin-top: 0;
    }

    .custom-block {
        display: block;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: auto;
        height: 100%;
        padding: 20px 0;
        border: 1px solid #CBD1DB;
        background-color: #F9FBFF;
        box-shadow: 0 12px 40px -25px rgba(0, 0, 0, 0.5);
        border-radius: 5px;

        p {
            a {
                color: $collection__link--color;
                font-weight: $collection__link--font-weight;
            }
        }
    }

    app-tag-block {
        .custom-block{
            background-color: transparent;
            color: initial;
            border: 0;
            box-shadow: none;
            padding: 0;
        }
    }

    app-presentation-general{
        h1{
            font-weight: bold;
            margin: 0;
            text-transform: uppercase;
        }
        div {
            width: 80%;
            float: left;
            &.no-image{
                width: calc(100% - 30px);
                float: none;
            }
        }
        img {
            width: 20%;
            padding:30px;
        }
        .custom-block{
            padding: 20px 0 20px 30px;
        }
    }

    app-faq-block{
        .custom-block{
            padding: 20px 30px;
        }
    }

    app-full-width-cta {

        .custom-block {
            padding: 20px 30px;

            img[style*="right"] {
                margin-bottom: 20px;
                margin-left: 30px;
            }
        }
    }

    app-half-width-cta{
        width: calc(50% - 21px);
        
        .custom-block{
            padding: 20px 30px;
        }
    }

    app-key-figures{
        .custom-block{
            padding: 20px 0;
        }
    }

    app-presentation-general, app-full-width-cta, app-tag-block, app-faq-block{
        width: 100%;
        .custom-block {
            width: 100%;
        }
    }

    app-faq-block {
        mat-form-field {
            width: 50%;
        }

        mat-expansion-panel {
            box-shadow: none !important;
            background: none !important;
            padding: 0 5px;
        }

        mat-expansion-panel.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header .mat-icon, mat-expansion-panel.mat-expansion-panel.mat-expanded .mat-expansion-panel-header .mat-icon {
            transform: none;
            margin-right: 0;
        }

        mat-expansion-panel.mat-expansion-panel.mat-expanded > .mat-expansion-panel-header .mat-expansion-panel-header-title > .mat-icon {
            transform: rotate(180deg);
        }

        .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
            display: flex;
            flex-grow: 1;
            margin-right: 0;
            align-items: center;
        }

        .mat-expansion-panel-header-title {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #323A49;
        }

        .mat-expansion-panel-header-description {
            b {
                background: #037CC2;
                border-radius: 17px;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
                padding: 5px 14px;
                margin: 0 2px;
            }
        }

        .mat-accordion .mat-expansion-panel {
            border-radius: 5px;
            margin: 13px 3px;
        }

        mat-panel-title{
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header, mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header:hover {
            background-color: #F9FBFF !important;
            border: 0.5px solid $tabs__ink-bar--background-color;
            border-radius: 5px;
            color: #323A49;
        }

        mat-expansion-panel.mat-expansion-panel.mat-expanded .mat-expansion-panel-header {
            box-shadow: 0px 12px 13px -12px rgba(0, 0, 0, 0.25);
        }

        dl dt, dl dd {
            display: block;
        }

        dl dt::after {
            content: "";
        }

        .mat-paginator {
            margin: 0 8px;
        }

        .mat-expansion-panel-body {
            background: #F9FBFF;
            border: 1px solid #CBD1DB;
            border-radius: 0 0 5px 5px;
            margin: -1px 10px 0;
            padding: 0;
            font-style: normal;
            font-size: 14px;
            line-height: 16px;
            color: #323A49;

            p {
                padding: 0 15px;
            }
        }

        .faq-banner {
            background: #323A49;
            border: 0.5px solid #CBD1DB;
            border-radius: 5px;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #FFFFFF;
            border-radius: 5px;
            padding: 10px 15px;
            margin: 0 8px;

            span {
                padding: 0 15px 0 15px;
            }

            span:nth-child(1) {
                width: 75%;
                max-width: 75%;
                display: inline-block;
            }
        }

        .mat-accordion > .mat-expansion-panel-spacing:last-child, .mat-accordion > :last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
            margin-bottom: 13px;
        }

        .mat-accordion > .mat-expansion-panel-spacing:first-child, .mat-accordion > :first-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
            margin-top: 13px;
        }
    }

    app-key-figures{
        width: calc(25% - 32px);

        span {
            display: block;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            padding-top: 0;
            p{
                padding: 0 10px;
                margin-top: 0;
                margin-bottom: 32px;
            }
        }
        .key {
            text-align: center;
            width: 100%;
            display: block;
            font-size: 100px;
            color: $activities__button-selected--background-color;
        }
    }
}