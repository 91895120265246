@use '@angular/material' as mat;
@import '@angular/material/theming';

/* http://mcg.mbitson.com/#!?rose=%23ee0072&blue=%2300afec&orange=%23fcb731&gray=%23939598&bluedark=%23004157&themename=mathia#%2F */


$mat-white: (
    500: white,
    contrast: (
        500: $black-87-opacity
    )
);

$mat-black: (
    500: black,
    contrast: (
        500: white,
    )
);

$md-accent: ( // --bje-jaune
    50 : #fffde0,
    100 : #fffab3,
    200 : #fff680,
    300 : #fff24d,
    400 : #fff026,
    500 : #ffed00,
    600 : #ffeb00,
    700 : #ffe800,
    800 : #ffe500,
    900 : #ffe000,
    A100 : #ffffff,
    A200 : #fffdf2,
    A400 : #fff6bf,
    A700 : #fff3a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-red: (
    50 : #fce5e3,
    100 : #f8beb9,
    200 : #f3938b,
    300 : #ee685c,
    400 : #ea4739,
    500 : #e62716,
    600 : #e32313,
    700 : #df1d10,
    800 : #db170c,
    900 : #d50e06,
    A100 : #fffdfd,
    A200 : #ffcbca,
    A400 : #ff9997,
    A700 : #ff807e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$mat-fusedark: ( // bjgrisclair
    50 : #fdfdfd,
    100 : #f9f9f9,
    200 : #f5f5f5,
    300 : #f1f1f1,
    400 : #eeeeee,
    500 : #ebebeb,
    600 : #e9e9e9,
    700 : #e5e5e5,
    800 : #e2e2e2,
    900 : #dddddd,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the Material palettes
$primary: mat.define-palette($mat-fusedark, 500);
$accent: mat.define-palette($md-accent, 500);
$warn: mat.define-palette($md-red, 500);

// Force the input field font sizes to 16px
$typography: mat.define-legacy-typography-config(
    $font-family: '#{var(--typo1)}, "Helvetica Neue", Arial, sans-serif',
    $body-1: mat.define-typography-level(14px, 1.4, 400),
    $input: mat.define-typography-level(14px, 1.125, 300, #{var(--typo1)}, 0.25),
    $title: mat.define-typography-level(24px, 28px, 700, #{var(--typo1)}),
    $button: mat.define-typography-level(
        $font-size: #{set-fluid-typo(14px, 18px)},
        $font-weight: 700,
        $letter-spacing: normal,
        $line-height: 1.4,
    )
);

// Create the Material theme object
$theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $accent,
        warn:$warn
    ),
    typography: $typography,
    density: 0,
));

// Store the background and foreground colors for easier access
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

$activities-card: mat.define-legacy-typography-config(
    $title: mat.define-typography-level(14px, 17px, 700, #{var(--typo1)}, 0.005em )
);

