app-followed-tabs {
    justify-content: space-evenly;
    background-color: white;

    app-followed-list, app-followed-log-book {
        .content {
            display: initial !important;
        }

        mat-spinner{
            margin: auto;
        }
    }

    #id-app-followed-log-book { // news best selector sonar compatiblity
        table tr, table tr.mat-row {
            cursor: default;
        }
    }

    .one-tab{
        .mat-tab-label-container{
            display: none;
        }
    }
}

app-followed-list {
    table tbody tr:hover {
        background-color: inherit;
        cursor: initial;
    }

    table tbody tr.selected .mat-cell, table tbody tr:hover .mat-cell {
        color: if(variable-exists(assignations__table__row--hover--color), $assignations__table__row--hover--color,  rgba(0, 0, 0, 0.87));
    }

    .mat-column-buttons {
        .buttons-wrapper {
            display: flex;
            justify-content: flex-end;
            button {
                width: auto;

                &.mat-raised-button {
                    margin: 0 auto;
                }
            }
        }
    }

}
