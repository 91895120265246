%layout--g4x4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

@mixin layout--matching-activity {
    $min-cell-size: 5rem;

    .mat-card-content {
        flex: none;
        align-self: center;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        align-items: stretch;
        @include g-3;
        max-width: 150rem;
        max-height: calc(100vh - 35rem);
        @include px-3;
        @include my-auto;

        @media (orientation: landscape) {
            grid-template-columns: auto;
            grid-template-rows: repeat(2, 1fr);
            align-items: start;
        }

        #container-for-answers-selected, // order-matching
        #container-for-answers-available, // order-matching
        .audio-answers, // text-matching
        .image-answers { // text-matching
            flex: 1;
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: repeat(auto-fill, minmax($min-cell-size, 1fr));
            gap: inherit;
            width: unset;
            max-width: 100%;
            height: 100%;
            min-height: min-content;
            max-height: unset;
            margin: unset !important;

            @media (orientation: landscape) {
                grid-template-columns: repeat(auto-fill, minmax($min-cell-size, 1fr));
                grid-template-rows: auto;
                width: 100%;
                max-width: unset;
            }

            @for $i from 2 through 10 { // Enforce dynamic grid layout (repeat function with auto-fill argument is not reliable in this case)

                &.container-for-answers-selected--grid-#{$i}, // order-matching
                &.container-for-answers-available--grid-#{$i}, // order-matching
                &.audio-answers--grid-#{$i}, // text-matching
                &.image-answers--grid-#{$i} { // text-matching
                    grid-template-columns: auto;
                    grid-template-rows: repeat(#{$i}, 1fr);

                    @media (orientation: landscape) {
                        grid-template-columns: repeat(#{$i}, 1fr);
                        grid-template-rows: auto;
                    }
                }
            }

            .button-order, // order-matching
            .button-image, // order-matching
            button { // text-matching
                display: flex;
                justify-content: center;
                align-items: center;
                width: auto;
                min-width: $min-cell-size;
                height: 100%;
                min-height: $min-cell-size;
                aspect-ratio: 16 / 9;
                padding: unset;

                img {
                    max-height: 100%;
                    border-radius: 10px;
                }
            }

            button { // text-matching
                @extend %answer;
            }
        }

        #container-for-answers-available, // order-matching
        .audio-answers { // text-matching
            order: 1;
            justify-self: end;
            justify-items: end;

            @media (orientation: landscape) {
                order: 2;
                justify-self: unset;
                align-self: start;
                justify-items: center;
            }
        }

        #container-for-answers-selected, // order-matching
        .image-answers { // text-matching
            order: 2;
            justify-self: start;
            justify-items: start;

            @media (orientation: landscape) {
                order: 1;
                justify-self: unset;
                align-self: end;
                justify-items: center;
            }
        }
    }
}