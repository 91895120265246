// || STRUCTURE CSS DOM PARENT
// #lessons app-stepper-wrapper > .page-layout > .center content .outlet-player-navigation {

/*
app-multi
    .app-multi__content [row || column] ___________________________________________________________
        |                        |  .app-multi__content__router-wrapper   |                    |
        |                        |                                           |                    |
        |.multi-side-nav-previous|           ****  activities  ****          |.multi-side-nav-next|
        |                        |                                           |                    |
        |                        |                                           |                    |
    _______________________________________________________________________________________________
*/


// || STRUCTURE CSS DOM
/*
//                                  APP-MULTI
//                                      div.page-layout.simple.fullwidth
//                                          div.content
//                                              div.multi-side-nav-previous
//                                                  CONTENT.multi-content
//                                                      router-outlet
//                                                      ACTIVITIES COMPONENT
*/


app-multi {
    .multi-side-nav-previous,
    .multi-side-nav-next {
        display: none !important;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        place-content: stretch center;
        align-items: stretch;
    }
}

.app-multi__content {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    flex: 1;
}
.app-multi__content__router-wrapper {
    // <router-outlet></router-outlet>
    // ****  activities  ****
}




