@use '@angular/material' as mat;

//@include mat.all-legacy-component-typographies($typography);

/* ****************************** PLACEHOLDERS ****************************** */

/* H1 */

%font-h1 {
    @include mat-typography-level-to-styles($typography, 'headline'); // Emit all CSS styles of the headline typography level
}
%font-headline {
    @extend %font-h1;
}

%font-h1-ff {
    font-family: mat.font-family($typography, 'headline');
}

%font-h1-fs {
    font-size: mat.font-size($typography, 'headline');
}

%font-h1-fw {
    font-weight: mat.font-weight($typography, 'headline');
}

%font-h1-ls {
    letter-spacing: mat.letter-spacing($typography, 'headline');
}

%font-h1-lh {
    line-height: mat.line-height($typography, 'headline');
}

/* H2 */

%font-h2 {
    @include mat-typography-level-to-styles($typography, 'title'); // Emit all CSS styles of the title typography level
}
%font-title {
    @extend %font-h2;
}

%font-h2-ff {
    font-family: mat.font-family($typography, 'title');
}

%font-h2-fs {
    font-size: mat.font-size($typography, 'title');
}

%font-h2-fw {
    font-weight: mat.font-weight($typography, 'title');
}

%font-h2-ls {
    letter-spacing: mat.letter-spacing($typography, 'title');
}

%font-h2-lh {
    line-height: mat.line-height($typography, 'title');
}

/* H3 */

%font-h3 {
    @include mat-typography-level-to-styles($typography, 'subheading-2'); // Emit all CSS styles of the subheading-2 typography level
}
%font-subheading-2 {
    @extend %font-h3;
}

%font-h3-ff {
    font-family: mat.font-family($typography, 'subheading-2');
}

%font-h3-fs {
    font-size: mat.font-size($typography, 'subheading-2');
}

%font-h3-fw {
    font-weight: mat.font-weight($typography, 'subheading-2');
}

%font-h3-ls {
    letter-spacing: mat.letter-spacing($typography, 'subheading-2');
}

%font-h3-lh {
    line-height: mat.line-height($typography, 'subheading-2');
}

/* H4 */

%font-h4 {
    @include mat-typography-level-to-styles($typography, 'subheading-1'); // Emit all CSS styles of the subheading-1 typography level
}
%font-subheading-1 {
    @extend %font-h4;
}
%font-h4-ff {
    font-family: mat.font-family($typography, 'subheading-1');
}

%font-h4-fs {
    font-size: mat.font-size($typography, 'subheading-1');
}

%font-h4-fw {
    font-weight: mat.font-weight($typography, 'subheading-1');
}

%font-h4-ls {
    letter-spacing: mat.letter-spacing($typography, 'subheading-1');
}

%font-h4-lh {
    line-height: mat.line-height($typography, 'subheading-1');
}

/* BASE BODY TEXT */

%font-body-1 {
    @include mat-typography-level-to-styles($typography, "body-1"); // Emit all CSS styles of the body-1 typography level
}

%font-body-1-ff {
    font-family: mat.font-family($typography, 'body-1');
}

%font-body-1-fs {
    font-size: mat.font-size($typography, 'body-1');
}

%font-body-1-fw {
    font-weight: mat.font-weight($typography, 'body-1');
}

%font-body-1-ls {
    letter-spacing: mat.letter-spacing($typography, 'body-1');
}

%font-body-1-lh {
    line-height: mat.line-height($typography, 'body-1');
}

/* BOLDER BODY TEXT */

%font-body-2 {
    @include mat-typography-level-to-styles($typography, "body-2"); // Emit all CSS styles of the body-2 typography level
}

%font-body-2-ff {
    font-family: mat.font-family($typography, 'body-2');
}

%font-body-2-fs {
    font-size: mat.font-size($typography, 'body-2');
}

%font-body-2-fw {
    font-weight: mat.font-weight($typography, 'body-2');
}

%font-body-2-ls {
    letter-spacing: mat.letter-spacing($typography, 'body-2');
}

%font-body-2-lh {
    line-height: mat.line-height($typography, 'body-2');
}

/* SMALLER BODY TEXT */

%font-caption {
    @include mat-typography-level-to-styles($typography, "caption"); // Emit all CSS styles of the caption typography level
}

%font-caption-ff {
    font-family: mat.font-family($typography, 'caption');
}

%font-caption-fs {
    font-size: mat.font-size($typography, 'caption');
}

%font-caption-fw {
    font-weight: mat.font-weight($typography, 'caption');
}

%font-caption-ls {
    letter-spacing: mat.letter-spacing($typography, 'caption');
}

%font-caption-lh {
    line-height: mat.line-height($typography, 'caption');
}

/* BUTTONS */

%font-button {
    @include mat-typography-level-to-styles($typography, "button"); // Emit all CSS styles of the button typography level
}

%font-button-ff {
    font-family: mat.font-family($typography, 'button');
}

%font-button-fs {
    font-size: mat.font-size($typography, 'button');
}

%font-button-fw {
    font-weight: mat.font-weight($typography, 'button');
}

%font-button-ls {
    letter-spacing: mat.letter-spacing($typography, 'button');
}

%font-button-lh {
    line-height: mat.line-height($typography, 'button');
}

/* FORM INPUT FIELDS */

%font-input {
    @include mat-typography-level-to-styles($typography, "input"); // Emit all CSS styles of the input typography level
}

%font-input-ff {
    font-family: mat.font-family($typography, 'input');
}

%input-fs {
    font-size: mat.font-size($typography, 'input');
}

%input-fw {
    font-weight: mat.font-weight($typography, 'input');
}

%input-ls {
    letter-spacing: mat.letter-spacing($typography, 'input');
}

%input-lh {
    line-height: mat.line-height($typography, 'input');
}