/* ****************************** RADIUS ****************************** */

@mixin r-0 {
    border-radius: $radius-0; // 0
}
%r-0 {
    @include r-0;
}

@mixin r-1 {
    border-radius: $radius-1; // 8px to 12px
}
%r-1 {
    @include r-1;
}

@mixin r-2 {
    border-radius: $radius-2; // 12px to 26px
}
%r-2 { // 12px to 26px
    @include r-2;
}

@mixin r-3 {
    border-radius: $radius-3; // 16px to 24px
}
%r-3 {
    @include r-3;
}

@mixin r-4 {
    border-radius: $radius-4; // 24px to 32px
}
%r-4 {
    @include r-4;
}

@mixin r-5 {
    border-radius: $radius-5; // 32px to 64px
}
%r-5 {
    @include r-5;
}

@mixin r-rounded {
    border-radius: 50px;
}
%r-rounded {
    @include r-rounded;
}

/* TOP SIDE */

@mixin rt-0 {
    border-top-left-radius: $radius-0; // 0
    border-top-right-radius: $radius-0; // 0
}
%rt-0 {
    @include rt-0;
}

@mixin rt-1 {
    border-top-left-radius: $radius-1; // 8px to 12px
    border-top-right-radius: $radius-1; // 8px to 12px
}
%rt-1 {
    @include rt-1;
}

@mixin rt-2 {
    border-top-left-radius: $radius-2; // 12px to 26px
    border-top-right-radius: $radius-2; // 12px to 26px
}
%rt-2 {
    @include rt-2;
}

@mixin rt-3 {
    border-top-left-radius: $radius-3; // 16px to 24px
    border-top-right-radius: $radius-3; // 16px to 24px
}
%rt-3 {
    @include rt-3;
}

@mixin rt-4 {
    border-top-left-radius: $radius-4; // 24px to 32px
    border-top-right-radius: $radius-4; // 24px to 32px
}
%rt-4 {
    @include rt-4;
}

@mixin rt-5 {
    border-top-left-radius: $radius-5; // 32px to 64px
    border-top-right-radius: $radius-5; // 32px to 64px
}
%rt-5 {
    @include rt-5;
}

/* BOTTOM SIDE */

@mixin rb-0 {
    border-bottom-left-radius: $radius-0; // 0
    border-bottom-right-radius: $radius-0; // 0
}
%rb-0 {
    @include rb-0;
}

@mixin rb-1 {
    border-bottom-left-radius: $radius-1; // 8px to 12px
    border-bottom-right-radius: $radius-1; // 8px to 12px
}
%rb-1 {
    @include rb-1;
}

@mixin rb-2 {
    border-bottom-left-radius: $radius-2; // 12px to 26px
    border-bottom-right-radius: $radius-2; // 12px to 26px
}
%rb-2 {
    @include rb-2;
}

@mixin rb-3 {
    border-bottom-left-radius: $radius-3; // 16px to 24px
    border-bottom-right-radius: $radius-3; // 16px to 24px
}
%rb-3 {
    @include rb-3;
}

@mixin rb-4 {
    border-bottom-left-radius: $radius-4; // 24px to 32px
    border-bottom-right-radius: $radius-4; // 24px to 32px
}
%rb-4 {
    @include rb-4;
}

@mixin rb-5 {
    border-bottom-left-radius: $radius-5; // 32px to 64px
    border-bottom-right-radius: $radius-5; // 32px to 64px
}
%rb-5 {
    @include rb-5;
}

/* LEFT SIDE */

@mixin rl-0 {
    border-top-left-radius: $radius-0; // 0
    border-bottom-left-radius: $radius-0; // 0
}
%rl-0 {
    @include rl-0;
}

@mixin rl-1 {
    border-top-left-radius: $radius-1; // 8px to 12px
    border-bottom-left-radius: $radius-1; // 8px to 12px
}
%rl-1 {
    @include rl-1;
}

@mixin rl-2 {
    border-top-left-radius: $radius-2; // 12px to 26px
    border-bottom-left-radius: $radius-2; // 12px to 26px
}
%rl-2 {
    @include rl-2;
}

@mixin rl-3 {
    border-top-left-radius: $radius-3; // 16px to 24px
    border-bottom-left-radius: $radius-3; // 16px to 24px
}
%rl-3 {
    @include rl-3;
}

@mixin rl-4 {
    border-top-left-radius: $radius-4; // 24px to 32px
    border-bottom-left-radius: $radius-4; // 24px to 32px
}
%rl-4 {
    @include rl-4;
}

@mixin rl-5 {
    border-top-left-radius: $radius-5; // 32px to 64px
    border-bottom-left-radius: $radius-5; // 32px to 64px
}
%rl-5 {
    @include rl-5;
}

/* RIGHT SIDE */

@mixin rr-0 {
    border-top-right-radius: $radius-0; // 0
    border-bottom-right-radius: $radius-0; // 0
}
%rr-0 {
    @include rr-0;
}

@mixin rr-1 {
    border-top-right-radius: $radius-1; // 8px to 12px
    border-bottom-right-radius: $radius-1; // 8px to 12px
}
%rr-1 {
    @include rr-1;
}

@mixin rr-2 {
    border-top-right-radius: $radius-2; // 12px to 26px
    border-bottom-right-radius: $radius-2; // 12px to 26px
}
%rr-2 {
    @include rr-2;
}

@mixin rr-3 {
    border-top-right-radius: $radius-3; // 16px to 24px
    border-bottom-right-radius: $radius-3; // 16px to 24px
}
%rr-3 {
    @include rr-3;
}

@mixin rr-4 {
    border-top-right-radius: $radius-4; // 24px to 32px
    border-bottom-right-radius: $radius-4; // 24px to 32px
}
%rr-4 {
    @include rr-4;
}

@mixin rr-5 {
    border-top-right-radius: $radius-5; // 32px to 64px
    border-bottom-right-radius: $radius-5; // 32px to 64px
}
%rr-5 {
    @include rr-5;
}