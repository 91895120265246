@use '@angular/material' as mat;

.mat-form-field-ripple {
    background-color: mat.get-color-from-palette($accent);
}

#skiptocontent a:focus {
    position: absolute;
    left: 0px;
    top: 0px;
    outline-color: transparent;
    -webkit-transition: top .1s ease-in;
    transition: top .1s ease-in;
    background: #BF1722;
}

#skiptocontent a {
    padding: 6px;
    position: absolute;
    top: -40px;
    left: 0px;
    color: white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    border-bottom-right-radius: 8px;
    background: #BF1722;
    -webkit-transition: top 1s ease-out;
    transition: top 1s ease-out;
    z-index: 100;
}
/**
    accessibility outline on focus when use tab
 */
*:focus-visible:not(input), .cdk-keyboard-focused {
    outline: mat.get-color-from-palette($accent) auto 1px !important;
}

*:focus:not(:focus-visible), *:hover, *:active {
    outline: none !important;
}

#skiptocontent a:focus {
    position: absolute;
    left: 0px;
    top: 0px;
    outline-color: transparent;
    -webkit-transition: top .1s ease-in;
    transition: top .1s ease-in;
    background: #BF1722;
}

#skiptocontent a {
    padding: 6px;
    position: absolute;
    top: -40px;
    left: 0px;
    color: white;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    border-bottom-right-radius: 8px;
    background: #BF1722;
    -webkit-transition: top 1s ease-out;
    transition: top 1s ease-out;
    z-index: 100;
}