app-memory-find-zone {



    mat-card.mat-card.activities-card & { // = mat-card.mat-card.activities-card app-memory-find-zone

        @extend %p-3;
        margin: 0 !important;
        max-width: 100%;
        max-height: fit-content;
        flex: 1;
        height: 100%;


        @include media-breakpoint-up('sm') {
            max-height: 45vh;
        }

        audio {
            @extend %visually-hidden;
        }

        .item-wrapper {
            background-color: #60a426;
        }

        button {
            // min-width: 18vh;
            &.card-is-returned {
                @include deco-memory(var(--primarybackground, #B10745));
                @include media-breakpoint-up('sm') { // lorsque les réponses sont de 8 et sur 2 lignes, le ? doit s'adapter à la largeur
                    font-size: 10vw !important;
                    line-height: 0 !important;
                }
            }

            img {
                // @extend %visually-hidden;
                // visibility: visible !important;
                flex: 1;
                position: absolute;
                top: 0;
                bottom: 0;
                height: 100%;
            }

            .mat-icon {
                display: none;

                [svg-icon="sound_off"] {

                }
            }

            .info {
                @extend %font-body-2;
                letter-spacing: 3px;
                @include text-border(var(--primaryborder, #4B001C));
                position: absolute;
                bottom: 10px;
                padding: 20px;

            }
        }

    }
}