@use '@angular/material' as mat;
.mat-column-nickname, .mat-column-pseudo, .mat-column-username {
    max-width: 160px;
}


app-group {

    .mat-column-code p {
        background-color: mat.get-color-from-palette($primary);
        display: inline-block;
        margin: 0;
        padding: 8px 27px;
        color: map_get($mat-white, 500);
        border-radius: 1.5em;
        text-transform: uppercase;
    }

}

app-users-import {
    mat-form-field {
        width: fit-content;
        min-width: 100%;

        .mat-form-field-wrapper {
            padding-bottom: 11px;
        }

        .mat-form-field-label-wrapper {
            overflow: inherit;
        }
    }
}

app-graph-group-management {
    .selected-exercice {
        color: map_get(mat.$grey-palette, 500);
        font-family: $font-family2;
    }
    .statistics-button {
        @extend %mat-stroked-button--primary;
        margin-left: auto !important;
    }

}

fuse-groups-list {
    > .page-layout {
        max-height: 100%;
        >.content {
            flex: unset !important;
            tr & {
                flex: none;
            }
        }
    }
}


.id-groups-form-dialog {
    .dialog-content-wrapper .mat-dialog-content form .mat-form-field-label {
        font: 500 14px/16px #{var(--typo1)}, #{var(--typo2)}, sans-serif; // mv theme
        color: $default-font-color;
    }
    .mat-chip.mat-standard-chip .mat-icon {
        background-color: transparent;
        color: white;
        opacity: 1;
    }
    .dialog-content-wrapper {
        .mat-dialog-actions {
            min-width: 66px;
        }
    }
}

.groups-inline-modal {
    margin: 0;

    .id-groups-form-dialog {
        margin: 0 8px;

        .mat-chip.mat-standard-chip.mat-chip-disabled {
            opacity: 1;
        }
    }

    .add-group-button,
    .add-learner-button {
        @extend %button--wide;
        margin-top: 16px;
        margin-bottom: 16px;
        justify-content: center !important;
    }

    tr & { // in component in table
        padding: 0;
        color: $default-font-color;
    }

    .save-button[disabled] .mat-icon svg path { // TODO remove default comportment with prefix for fill icons and stroke icons
        fill: none;
    }
}

.groups-inline-modal .id-groups-form-dialog .dialog-content-wrapper .mat-dialog-actions button,
#groups-list-table .id-groups-form-dialog .dialog-content-wrapper .mat-dialog-actions button {
    @extend %button--square-icon;

    .mat-icon {
        color: $button--accent-color;
    }
}

#groups-list-table table tbody .mat-row-editing,
#groups-list-table table tbody .mat-row-editing:hover {
    box-shadow: none;
    background: linear-gradient(180deg, rgba(50, 58, 73, 0) 0%, rgba(50, 58, 73, 0.2) 100%);
}

#groups-list-table .groups-inline-modal-learner table tbody tr:hover .mat-cell {
    color: $default-font-color;
}


#groups-list-table table tbody .mat-row-editing:hover {
    .groups-inline-modal-learner table tbody tr .mat-cell {
        color: $default-font-color;
    }
}