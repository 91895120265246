app-faq-list {
    mat-form-field {
        width: 50%;
    }

    mat-expansion-panel {
        box-shadow: none !important;
        background: none !important;
        padding: 0 5px;
    }

    mat-expansion-panel.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header .mat-icon, mat-expansion-panel.mat-expansion-panel.mat-expanded .mat-expansion-panel-header .mat-icon {
        transform: none;
        margin-right: 15px;
    }

    mat-expansion-panel.mat-expansion-panel.mat-expanded > .mat-expansion-panel-header .mat-expansion-panel-header-title > .mat-icon {
        transform: rotate(180deg);
    }

    .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
        display: flex;
        flex-grow: 1;
        margin-right: 16px;
        align-items: center;
    }

    .mat-expansion-panel-header-title {
        width: 75%;
        max-width: 75%;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #323A49;
    }

    .mat-expansion-panel-header-description {
        b {
            background: #037CC2;
            border-radius: 17px;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
            padding: 5px 14px;
            margin: 0 2px;
        }
    }

    .mat-accordion .mat-expansion-panel {
        border-radius: 5px;
        margin: 13px 3px;
    }

    mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header, mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header:hover {
        background-color: #F9FBFF !important;
        border: 0.5px solid #CBD1DB;
        border-radius: 5px;
        color: #323A49;
    }

    mat-expansion-panel.mat-expansion-panel.mat-expanded .mat-expansion-panel-header {
        box-shadow: 0px 12px 13px -12px rgba(0, 0, 0, 0.25);
    }

    dl dt, dl dd {
        display: block;
    }

    dl dt::after {
        content: "";
    }

    .mat-paginator {
        margin: 0 8px;
    }

    .mat-expansion-panel-body {
        background: #F9FBFF;
        border: 1px solid #CBD1DB;
        border-radius: 0 0 5px 5px;
        margin: -1px 10px 0;
        padding: 0;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        color: #323A49;

        p {
            padding: 0 15px;
        }
    }

    .faq-banner {
        background: #323A49;
        border: 0.5px solid #CBD1DB;
        border-radius: 5px;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        border-radius: 5px;
        padding: 10px 15px;
        margin: 0 8px;

        span {
            padding: 0 15px 0 15px;
        }

        span:nth-child(1) {
            width: 75%;
            max-width: 75%;
            display: inline-block;
        }
    }

    .mat-accordion > .mat-expansion-panel-spacing:last-child, .mat-accordion > :last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
        margin-bottom: 13px;
    }

    .mat-accordion > .mat-expansion-panel-spacing:first-child, .mat-accordion > :first-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
        margin-top: 13px;
    }

    a {
        color: $accent-c;
    }
}