/**
css for loading spinner and for desactivated all fields during loading
spinner use in directive SpinnerDirective that inject loader inside button using it in loading state
 */
@keyframes spinner-button {
    to {
        transform: rotate(360deg);
    }
}

.spinner-button:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid white;
    border-top-color: map-get($primary, 500);
    animation: spinner-button .8s linear infinite;
}

/**
when spinner is active on a button we hide text and show loader instead
it's use by the directive
 */
.spinner-active-hide-text {
    color: transparent !important;

}

.spinner-active-hide-icons {
    .mat-icon {
        display: none !important;
    }
}

// i use fieldset only to disabled all control doesn't want any design coming with it
.disabled-border-fieldset-when-loading {
    border: none !important;
    margin: 0px !important;
    padding: 0px !important;
    display: contents !important;
}