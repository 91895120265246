@use '@angular/material' as mat;
%button--radius {
    border-radius: 12px; // TODO gérer les tailles de radius tel que les spacing br-0 br-1 ...
}
%button--icon--left {
    // TODO gérer la positon de l'icon à droite
}
@mixin button-min-height {
    min-height: 30px;
}

%button--stroked--basic {
    // Layout
    padding-block: 10px;
    padding-inline: 20px;
    @include button-min-height;
    // Font
    @extend %font-button;
    // Style
    @extend %button--radius;
    border: 2px solid var(--primarybackground, #B10745);
    background: #FFF;
    //
    @include mat.elevation(2);
}

// Figma BOUTON PRIMARY - fond ROUGE bord BORDEAUX
/* answer, app-launcher, list, select ... */
@mixin button--primary--strocked--raised {
    // Layout
    flex: none; // il ne partage pas son espace
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-block: 10px;
    padding-inline: 20px;
    @include button-min-height;
    @extend %g-1;
    // Font
    @extend %font-button;
    // Style
    @extend %button--radius;

    color: var(--primarylabel, #fff);
    // Variation
    @extend %wrapper--primary--strocked--raised--variation;

    mat-icon.mat-icon,
    .mat-checkbox-label {
        color: var(--primarylabel, #fff);
    }
}

%button--primary--strocked--raised {
    @include button--primary--strocked--raised;
}

// Figma BOUTON SECONDAIRE SECONDARY - fond BLANC bord ROUGE
/* acti__button, dialog submit,  ... */
@mixin button--secondaire--strocked--raised {
    // Layout
    flex: none; // il ne partage pas son espace
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-block: 10px;
    padding-inline: 20px;
    gap: 10px;
    @include button-min-height;
    // Font
    @extend %font-button;
    // Style
    @extend %button--radius;

    // Variation
    @extend %wrapper--secondaire--strocked--raised--variation;
}
%button--secondaire--strocked--raised {
    @include button--secondaire--strocked--raised;
}

// Figma BOUTON TERTIAIRE - fond ROSE bord ROSE
/* btn fullscreen, media ... */
%button--tertiaire--strocked--raised {
    // Layout
    flex: none; // il ne partage pas son espace
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    @extend %px-1;
    @include button-min-height;
    // Font
    @extend %font-button;
    // Style
    @extend %button--radius;
    color: var(--tertiarylabel, #006AA6);

    // Variation
    @extend %wrapper--tertiaire--strocked--raised--variation;
}

// Figma BOUTON QUATERNAIRE - fond BLANC bord BLEU
/* ... */
%button--quaternaire--strocked--raised {
    // Layout
    flex: none; // il ne partage pas son espace
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    padding-block: 10px;
    @include px-2;
    @include button-min-height;
    // Font
    @extend %font-button;
    // Style
    @extend %button--radius;
    color: var(--quaternairelabel, #B10745); // +
    // Variation
    @extend %wrapper--quaternaire--strocked--raised--variation;
}

// Bouton zoom
/* _app-consignes.scss 27; _app-zoomable.scss */
@mixin app-zoomable {
    position: absolute;
    bottom: 5px;
    right: 5px;
    @extend %button--tertiaire--strocked--raised;
    @extend %px-1;
    min-height: 30px;
    height: auto;
    width: auto;

    mat-icon.mat-icon {
        color: var(--tertiaryicon, #B10745) !important;

        svg {
            width: 24px;
            height: 24px;
            min-width: 24px;
            min-height: 24px;
        }
    }
}