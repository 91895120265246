


// Figma RÉPONSE (REPONSE/TEXTE)
// Figma PRIMARY BTN - fond ROUGE bord BORDEAUX
@mixin wrapper--primary--strocked {
    // Style
    border: 2px solid var(--primaryborder, #4B001C);
    background: var(--primarybackground, #B10745);
}
@mixin wrapper--primary--strocked--raised {
    // Style PRIMARY BTN
    @include wrapper--primary--strocked;
    box-shadow: 0px 4px 0px 0px var(--primaryborder);
    margin-bottom: 4px;
    //---------------- ^^ mat. voir **1
}
%wrapper--primary--strocked--raised--variation {
    //// Font
    //color: var(--primarylabel, #FFFFFF);
    // Style
    @include wrapper--primary--strocked--raised;
    transition: all 0.055s linear 0s;

    &:hover, &:focus {
        // Style
        background: var(--primarybackground--hover, #C9084F);
    }

    &:active {
        //layout
        padding-block: 10px; // (-4px) On réduit la taille de la boîte car il n'y a plus l'épaisseur du shadow car le bouton s'abaisse.
        margin-top: 4px !important; // On compense la hauteur perdu ci-dessus pour conserver la hauteur du contenant
        margin-bottom: 0 !important;
        // Style
        color: var(--primarylabel--contrast, #FFF);
        background-color: var(--primaryborder, #4B001C);
        border: 2px solid var(--primarybackground, #B10745);
        box-shadow: none !important; // +

        mat-icon.mat-icon,
        .mat-checkbox-label {
            color: var(--primarylabel--contrast, #FFF);
        }
    }

    &[disabled],
    &.mat-checkbox-disabled { // DISABLED
        //layout
        padding-block: 10px; // (-4px) On réduit la taille de la boîte car il n'y a plus l'épaisseur du shadow car le bouton s'abaisse.
        margin-top: 4px !important; // On compense la hauteur perdu ci-dessus pour conserver la hauteur du contenant
        margin-bottom: 0;
        // Font
        color: var(--disabledstroke, #52758E);
        // Style
        border: 2px solid var(--disabledstroke, #52758E);
        background: var(--appbackground, #FBF5F7);
        box-shadow: none;
    }
}


// Figma BOUTON SECONDAIRE /* SECONDARY BTN */
// - fond BLANC bord ROUGE
@mixin wrapper--secondaire--strocked {
    // Style
    border: 2px solid var(--secondarylabel, #B10745);
    background: var(--secondarybackground, #FFF);
}
@mixin wrapper--secondaire--strocked--raised {
    // Style
    @include wrapper--secondaire--strocked;
    box-shadow: 0px 4px 0px 0px var(--secondarylabel);
    margin-bottom: 4px;
    //---------------- ^^ mat. voir **1
}
%wrapper--secondaire--strocked--raised--variation {
    //// Font
    //color: var(--secondarylabel, #B10745);
    // Style
    @include wrapper--secondaire--strocked--raised;
    transition: all 0.055s linear 0s;
    color: var(--secondarylabel); // +

    &:hover, &:focus {
        // Style
        // background: var(--secondarybackground--hover, #E6CFCF);
    }

    &:active {
        //layout
        padding-block: 10px !important; // (-4px) On réduit la taille de la boîte car il n'y a plus l'épaisseur du shadow car le bouton s'abaisse.
        margin-top: 4px !important; // On compense la hauteur perdu ci-dessus pour conserver la hauteur du contenant
        margin-bottom: 0 !important;
        // Style
        background: var(--tertiarybackground, #F7E6EC);
        box-shadow: none; // +
    }

    &[disabled] { // DISABLED
        //layout
        padding-block: 10px; // (-4px) On réduit la taille de la boîte car il n'y a plus l'épaisseur du shadow car le bouton s'abaisse.
        margin-top: 4px !important; // On compense la hauteur perdu ci-dessus pour conserver la hauteur du contenant
        // Font
        color: var(--disabledstroke, #52758E);
        // Style
        border: 2px solid var(--disabledstroke, #52758E);
        background: var(--appbackground, #FBF5F7);
        box-shadow: none;
    }

    &.incorrect {
        color: var(--primarylabel-contrast, #fff);
        border: 2px solid var(--badanswerstroke, #52758E);
        background: var(--badanswerbackground, #000A10);
        box-shadow: 0px 4px 0px 0px var(--badanswerstroke, #52758E);

        mat-icon.mat-icon {
            color: var(--primarylabel-contrast, #fff);
        }
    }

    &.correct,
    &.was-correct,
    &.currently-correct {
        color: var(--primarylabel-contrast, #fff);
        border: 2px solid var(--goodanswerstroke, #009E94);
        background: var(--goodanswerbackground, #027F77);
        box-shadow: 0px 4px 0px 0px var(--goodanswerstroke, #009E94);

        mat-icon.mat-icon {
            color: var(--primarylabel-contrast, #fff);
        }
    }
}

// Figma BOUTON TERITIAIRE — INSTRUCTION
// - fond ROSE bord ROSE
@mixin wrapper--tertiaire--strocked { // ENABLED
    // Style
    border: 2px solid var(--tertiaryborder, #F0D0DB);
    background: var(--tertiarybackground, #F7E6EC);
    box-shadow: none; // +
}
@mixin wrapper--tertiaire--strocked--raised {
    // Style
    @include wrapper--tertiaire--strocked;
    // box-shadow: 0px 4px 0px 0px #F0D0DB;
    box-shadow: 0px 4px 0px 0px var(--tertiaryborder);
    margin-bottom: 4px;
    //---------------- ^^ mat. voir **1
}
%wrapper--tertiaire--strocked--raised--variation {
    //// Font
    @include wrapper--tertiaire--strocked--raised;
    // Variation
    transition: all 0.055s linear 0s;

    &:hover, &:focus {
        // Style
        background: var(--tertiarybackground--hover, #FFF2F2);
    }

    &:active { // PRESSED
        //layout
        padding-block: 10px; // (-4px) On réduit la taille de la boîte car il n'y a plus l'épaisseur du shadow car le bouton s'abaisse.
        margin-top: 4px !important; // On compense la hauteur perdu ci-dessus pour conserver la hauteur du contenant
        margin-bottom: 0px !important;
        // Style
        @include wrapper--tertiaire--strocked;
        border: 2px solid var(--tertiarybackground, #F7E6EC);
        background: var(--tertiaryborder, #F0D0DB);
    }

    &[disabled] { // DISABLED
        //layout
        padding-block: 10px; // (-4px) On réduit la taille de la boîte car il n'y a plus l'épaisseur du shadow car le bouton s'abaisse.
        margin-top: 4px !important; // On compense la hauteur perdu ci-dessus pour conserver la hauteur du contenant
        margin-bottom: 0px !important;
        // Font
        color: var(--disabledstroke, #52758E);
        // Style
        @include wrapper--tertiaire--strocked;
        border: 2px solid var(--disabledstroke, #52758E);
        background: var(--appbackground, #FBF5F7);
        box-shadow: none;
    }
}

// Figma BOUTON QUATERNAIRE /* QUATERNAIRE BTN */
// - fond BLANC bord BLEU
@mixin wrapper--quaternaire--strocked {
    // Style
    border: 2px solid var(--quaternaireborder, #006AA6);
    background: var(--secondarybackground,#fff);
}
@mixin wrapper--quaternaire--strocked--raised {
    // Style
    @include wrapper--quaternaire--strocked;
    box-shadow: 0px 4px 0px 0px var(--quaternaireborder);
    margin-bottom: 4px;
    //---------------- ^^ mat. voir **1
}
%wrapper--quaternaire--strocked--raised--variation {
    //// Font
    //color: var(--quaternairelabel, #B10745);
    // Style
    @include wrapper--quaternaire--strocked--raised;
    transition: all 0.055s linear 0s;

    &:hover, &:focus {
        // Style
        // background: var(--quaternairebackground--hover, #E6CFCF);
        background: var(--quaternaireborder, #006AA6);
        color: var(--white);
    }

    &:active {
        //layout
        padding-block: 10px; // (-4px) On réduit la taille de la boîte car il n'y a plus l'épaisseur du shadow car le bouton s'abaisse.
        margin-top: 4px !important; // On compense la hauteur perdu ci-dessus pour conserver la hauteur du contenant
        // Style
        background: var(--quaternaireborder, #006AA6);
        color: var(--white);
        box-shadow: none; // +
    }

    &[disabled] { // DISABLED
        //layout
        padding-block: 10px; // (-4px) On réduit la taille de la boîte car il n'y a plus l'épaisseur du shadow car le bouton s'abaisse.
        margin-top: 4px !important; // On compense la hauteur perdu ci-dessus pour conserver la hauteur du contenant
        // Font
        color: var(--disabledstroke, #52758E);
        // Style
        border: 2px solid var(--disabledstroke, #52758E);
        background: var(--appbackground, #FBF5F7);
        box-shadow: none;
    }
}



%background-cover-base {
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        bottom: 0;
        right: 0;
        background-size: cover;
    }
}

%background-cover {
    @extend %background-cover-base;
    &::before {
        background-image: image("backgrounds/carte-londre.png");
    }
}

%background-cover-mobile {
    @extend %background-cover-base;
    &::before {
        background-image: image("backgrounds/carte-londre-mobile.png");
    }
}

@mixin text-border($color) { // var(--primaryborder, #4B001C)
    text-shadow: 2px 2px 0 #{$color},
    2px -2px 0 #{$color},
    -2px 2px 0 #{$color},
    -2px -2px 0 #{$color},
    2px 0px 0 #{$color},
    0px 2px 0 #{$color},
    -2px 0px 0 #{$color},
    0px -2px 0 #{$color};
}

@mixin deco-memory($color) { // var(--disabledstroke, #52758E);
    &:after { // on ajoute à tous les pristines
        content: "?";
        @extend %font-body-1;
        color: #{$color};
        font-size: 12rem;
        line-height: 12rem;
        @include text-border(var(--white, #fff));
        display: inline-table;
        width: auto;
        height: auto;
        position: absolute;
        text-align: center;
        text-indent: 0;
        top: 50%;
        left: 50%;
        transform: rotate(360deg) translate(-47%, -47%);
        transform-origin: 0 0;
    }
}



// ------------------ Note
/*
**1 - .mat-card>:last-child:not(.mat-card-footer),
.mat-card-content>:last-child:not(.mat-card-footer) {
    margin-bottom: 0;
}
*/




