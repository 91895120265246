// https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
@use '@angular/material' as mat;
@import "@fuse/scss/fuse";


#login {
    width: 100%;
    overflow: auto;


    #login-form-wrapper, #login-sso-wrapper {
        flex: 1 0 auto;
        padding: 32px;

        @include media-breakpoint('xs') {
            padding: 16px;
        }

        #login-form, #login-sso {
            width: 384px;
            max-width: 384px;
            padding: 32px;
            background: #FFFFFF;
            text-align: center;
            @include mat.elevation(16);

            @include media-breakpoint('xs') {
                padding: 24px;
                width: 100%;
            }

            .logo {
                width: 128px;
                margin: 32px auto;
            }

            .title {
                // font-size: 20px; must be set with material
                margin: 16px 0 32px 0;
            }

            form {
                width: 100%;
                text-align: left;

                mat-form-field {
                    width: 100%;
                }

                mat-checkbox {
                    margin: 0;
                }

                .remember-forgot-password {
                    margin-top: 8px;

                    .remember-me {
                        margin-bottom: 16px
                    }

                    .forgot-password {
                        font-weight: 500;
                        margin-bottom: 16px
                    }
                }

                .submit-button {
                    width: 220px;
                    margin: 16px auto;
                    display: block;

                    @include media-breakpoint('xs') {
                        width: 90%;
                    }
                }
            }

            .register {
                margin: 32px auto 24px auto;
                font-weight: 500;

                .text {
                    margin-right: 8px;
                }
            }

            .separator {
                font-size: 15px;
                font-weight: 600;
                margin: 24px auto;
                position: relative;
                overflow: hidden;
                width: 100px;
                color: rgba(0, 0, 0, 0.54);

                .text {
                    display: inline-flex;
                    position: relative;
                    padding: 0 8px;
                    z-index: 9999;

                    &:before, &:after {
                        content: '';
                        display: block;
                        width: 30px;
                        position: absolute;
                        top: 10px;
                        border-top: 1px solid rgba(0, 0, 0, 0.12);
                    }

                    &:before {
                        right: 100%;
                    }

                    &:after {
                        left: 100%;
                    }
                }
            }

            button {

                &.google,
                &.facebook {
                    width: 192px;
                    text-transform: none;
                    color: #FFFFFF;
                }

                @include media-breakpoint('xs') {
                    width: 80%;
                }

                &.google {
                    background-color: #D73D32;
                    margin-bottom: 8px;
                }

                &.facebook {
                    background-color: rgb(63, 92, 154);
                }
            }
            
            app-sessions-list {
                display: flex;
                flex-direction: column;
                gap: 16px;
                margin: 16px 0;
                    
                .cached-user {
                    display: flex;
                    justify-content: center;
                    
                    button:first-child {
                        flex: 1;
                        text-transform: capitalize;
                        
                        .mat-button-wrapper {
                            justify-content: space-between;
                        };
                    }
                }
            }
        }
    }

    .social-connect {
        display: none;
    }

    .register {
        flex-direction: column;
        place-content: center;
        align-items: center;
        box-sizing: border-box;
        display: flex;
    }
}


#register {

    .mat-form-field {
        padding: 0 5px;
    }

    jhi-re-captcha {
        margin-top: 20px;
    }

    .level-input-hide {
        visibility: hidden;
    }

}


#forgot-password {
    width: 100%;
    overflow: auto;
    background: image('backgrounds/dark-material-bg.jpg') no-repeat;
    background-size: cover;

    #forgot-password-form-wrapper {
        flex: 1 0 auto;
        padding: 32px;

        @include media-breakpoint('xs') {
            padding: 16px;
        }

        #forgot-password-form {
            width: 384px;
            max-width: 384px;
            padding: 32px;
            text-align: center;
            background: #FFFFFF;
            @include mat.elevation(16);

            @include media-breakpoint('xs') {
                padding: 24px;
                width: 100%;
            }

            .logo {
                width: 128px;
                margin: 32px auto;
            }

            .title {
                font-size: 20px;
                margin: 16px 0 32px 0;
            }

            form {
                width: 100%;
                text-align: left;

                mat-form-field {
                    width: 100%;
                }

                .submit-button {
                    min-width: 240px;
                    margin: 16px auto;
                    display: block;

                    @include media-breakpoint('xs') {
                        width: 90%;
                    }
                }
            }

            .login {
                margin: 32px auto 24px auto;
                width: 250px;
                font-weight: 500;

                .text {
                    margin-right: 8px;
                }
            }
        }
    }
}



#login {

    div#login-form-wrapper {

        #login-form {
            @include media-breakpoint-down('xs') {
                width: auto !important;
            }
        }
    }
}