
// https://a11y-guidelines.orange.com/en/articles/accessible-hiding/

%visually-hidden {
    position: absolute;
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0,0,0,0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}
.visually-hidden { // .cdk-visually-hidden possible aussi
    @extend %visually-hidden;
}

// voir node_modules/@angular/cdk/a11y/_index.scss si besoin
// @mixin a11y-visually-hidden()
