@use 'node_modules/@angular/material/index' as mat;
app-consignes {
    padding-inline: var(--padding-lateral-viewport);

    .container {
        // wrapper HTML inutile // en fait si car il ne faut pouvoir générer de l'espace dans l'élément.

        // Layout
        display: flex;
        width: 100%;
        align-items: center;
        @extend %px-2; // padding horizontal
        @extend %g-3; // gap
        // Style
        @extend %r-2;
        @extend %button--tertiaire--strocked--raised;
        min-height: 60px;
        color: var(--tertiarylabel, #006AA6); // overide button color

        > div {
            flex: 1;
            display: flex;
            align-items: center;
        }



        &.consigneImg,
        &.consigne-img { // 2 fonctionnements pour la même chose Grrr HTML entraine CSS compliqué pour rien
            padding: 0;
            position: relative; // pour #consigneImg
            flex-direction: column;
            @include g-0;

            [svgicon="image__button-fullscreen"] {
                @include app-zoomable;
            }

            img {
                border-radius: 10px;
            }

            &.legende img {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        mat-icon.mat-icon {
            @include ml-auto;
            color: var(--tertiaryicon, #B10745);
        }

        //.tts.wording.is-instruction {
        .bem-consignes__content { // remplace les divs parents inutiles
            flex: 1;
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            flex-wrap: wrap;

            mat-card-header & { // = mat-card-header app-consignes .bem-consignes__content
                @extend %font-body-2;
            }
            mat-card-content & { // = mat-card-content app-consignes .bem-consignes__content
                // legende
                @extend %font-body-1;
            }
        }
        audio {
            width: 100%;
            min-height: 50px;
        }
    }
}


.mat-card-content > app-consignes { // .mat-card-content > app-consignes ( " > " car page summary inclue dans des carte )
    @include media-breakpoint-up('sm'){
        max-width: var(--max-small-device);
    }
    width: 100%;
}
