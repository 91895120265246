app-what-is-missing-audio mat-card.activities-card,
app-what-is-missing mat-card.activities-card {


    mat-card-footer.mat-card-footer {
        flex-direction: column;
        gap: 20px;

        audio {
            display: none;
        }
    }

    &.hidden-answers {
        @include media-breakpoint-up('sm') {
            flex-direction: row;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;

            mat-card-header.mat-card-header {
                flex: 1;
                min-width: 100%;
            }
            mat-card-content.mat-card-content {
                flex: 5;
            }
            mat-card-footer.mat-card-footer {
                flex: 4;
            }
        }
    }
}
