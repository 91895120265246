// @import "debug";
@import "variables_activities";
// @import "../../../themes/langues_app/variables"; // côté app
// @import "../../../themes/langues_app/variables-colors"; // côté app
// @import "../../../themes/shared_theme/theme-langue_app/variables"; // côté plateforme
//@import "../../../themes/humanum/variables-humanum-colors"; // côté plateforme

// fix l'influence de certains css impactant le css d'activites
#lessons {
    // @import "../../../themes/shared_theme/theme-langue_app/activities-module-compatibility-plateforme-fixs"; // côté plateforme
}

// DOSSIER D’ABSTRACTIONS
/*
*   Le dossier abstracts/ regroupe les outils et helpers Sass utilisés à travers le projet. Toutes les variables globales,
*   les fonctions, les mixins et les placeholders devraient se retrouver dans ce dossier.
*
*   La règle générale concernant ce dossier est qu’il ne devrait pas retourner une seule ligne de CSS
*   s’il était compilé seul. Ce ne sont ici que des helpers Sass.
*
 */
//@import "../../../themes/langues_app/abstracts"; // côté app
@import "../../../@modules/activities/scss/shared_theme/abstracts"; // côté plateforme


// DOSSIER BASE (Base du theme de l'instance)
/*
*   Le dossier base/ contient ce que nous pourrions appeler le code standard (boilerplate) du projet.
*   On pourrait y trouver par exemple le fichier de reset, quelques règles typographiques,
*   et probablement une feuille de style définissant quelques styles standard
*   pour les éléments HTML les plus employés (_base.scss).
*
 */
@import "../../../themes/shared_theme/theme-langue_app/base/base"; // côté plateforme
@import "../../../themes/shared_theme/theme-langue_app/base/typography"; // côté plateforme - système typos fluides


// DOSSIER COMPOSANTS
/*
*   Pour les plus petits composants, il y a le dossier components/.
*   Alors que layout/ est macro (c’est-à-dire qu’il définit l’armature globale),
*   components/ est plus centré sur les widgets.
*   Il contient toutes sortes de modules spécifiques tels qu’un slider, un loader,
*   un widget et toutes ces sortes de choses.
*   Il y a en général de nombreux fichiers dans components/ car
*   l’application tout entière devrait être essentiellement constituée de petits modules.
*
 */
 @import "components/icons";
.activities-card { // Encapsulation
    @import "components/video";
    @import "components/app-zoomable";
    @import "components/activities-card__buttons";
    @import "components/answer";
    @import "components/media";
    @import "components/flashcard";
    @import "components/fields";
}
// @import "components/flashcard"; // --> answer
// @import "components/app-keyboard-latex-field";

// DOSSIER LAYOUT
/*
*   Le dossier layout/ contient tout ce qui concerne la mise en page du site ou de l’application.
*   Ce dossier pourrait intégrer des feuilles de style pour les principales
*   parties du site (header, footer, navigation, sidebar…), pour le système de grille
*   ou même les styles CSS pour tous les formulaires.
 */
//---- Player d'Activités
#lessons {
    @import "layout/player/app-progress-bar-multi-zone";
    @import "layout/player/app-stepper-wrapper";
    @import "layout/player/app-generic-stepper";
    @import "layout/player/app-multi";
    @import "layout/player/app-progress-bar-generic";
    @import "layout/player/stepper-wrapper__header";
}
//@import "app-generic-stepper";
//---- Activité
/*
*   Le dossier activities/ contient tout ce qui concerne la mise en page de l'activités.
*   parties des activités (header, consignes…), pour construire les activités
*
 */
//@import "layout/activities/activities__answers-status";
.activities-card {
    @import "layout/activities/acti__mat-card"; // Enveloppe principal des exercices
    @import "layout/activities/acti__mat-card-header";
    @import "layout/activities/acti__mat-card-content";
    @import "layout/activities/acti__mat-card-footer";
    @import "layout/activities/app-consignes";
}
//@import "layout/activities/activities-card__header";
//@import "layout/activities/activities-card--two-columns-ubo";
//@import "layout/activities/activities-card__consignes";
//@import "layout/activities/activities-card__answers-selected";
// @import "layout/activities/activities-card__answers-available";
//@import "layout/activities/activities-card__answers-available-text-version";
// @import "activities__components";
// @import "activities-card--two-columns"; // erasme
//---- Typologies
/*
*   Le dossier layout/typologie contient tout ce qui concerne la mise en page des exercices de façon spécifique.
*   Ce dossier ne devrait pas exister
 */
// @import "layout/typologies/app-multimedia";
@import "layout/typologies/app-what-is-missing-and-audio";
@import "layout/typologies/true-false";
@import "layout/typologies/app-multimedia";
@import "layout/typologies/app-text-matching";
@import "layout/typologies/app-order-matching";
@import "layout/typologies/app-read-pdf";
@import "layout/typologies/app-tool";
@import "layout/typologies/app-memory";
@import "layout/typologies/app-memory-find-zone";
@import "layout/typologies/app-voice-recorde-activity";
@import "layout/typologies/app-image-zoning";
@import "layout/typologies/app-image-zoning-play-sound";


// DOSSIER PAGES
/*
*   Si vous avez des styles spécifiques à certaines pages, il est préférable de les inclure à l’intérieur d’un dossier
*   pages/ dans un fichier portant le nom de la page. Par exemple, il n’est pas rare d’avoir des styles très spécifiques
*   pour la page d’accueil, d’où la nécessité d’un fichier _home.scss dans pages/.
 */
// @import "pages/_app-reward-page";
// @import "pages/_fuse-app-lessons-list";
// @import "pages/_fuse-app-lessons";
@import "pages/_summary-sub-lesson";