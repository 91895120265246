.mat-menu-panel {
    min-height: auto !important;

    .mat-menu-content {
        display: flex;
        flex-direction: column;
        padding: 0 !important;


        .mat-menu-item {
            justify-content: flex-start;
            align-items: center;
            display: flex;
            min-height: 30px;
            margin: 0 !important;
            padding: 0 16px;
            margin: 0;

            border-radius: 0 !important;
            background-color: var(--anthracite);
            background: $menu-panel__button--background-color;


            color: $menu-panel__button--color;
            font: $menu-panel--font;

            &:hover {
                background: $accent-c;
            }

            > .mat-icon {
                display: inline-block;
                order: 2;
                margin-left: 16px;
                margin-right: 0;
                color: $card-action__mat-menu-panel__mat-icon--color;
            }

            span {
                order: 1;
                margin-right: auto;
            }
        }

    }
}