$color-header: $white-c;

app-lesson-card-breadcrumb, breadcrumb {
    flex: 1;
    display: flex;
    background-color: $card__header--background-color;

    padding: 12px 20px;

    .lesson-card-breadcrump__nav {
        flex: 1;
        display: flex;
    }

    ol {
        display: flex;
        flex: 1;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .lesson-card-breadcrump__nav__item {
        display: flex;
        align-items: center;

        a, span {
            font-weight: 700;
            color: white;
        }
        a {
            text-decoration: underline;
        }
    }

    .lesson-card-breadcrump__nav__item__separator {
        padding: 0 1rem;
        text-decoration: inherit;
        color: white;
    }
    .lesson-card-breadcrump__nav__word__separator {
        padding: 0 0.5rem 0 0;
    }

}

.mat-card.mat-card {

    .card-split-media-info & {
        min-height: 278px;
        background-color: map-get($mat-white, 500);

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;

        mat-card-header {
            background-color: $card__header--background-color;
            z-index: 10;
            min-height: 72px;

            flex: 1 1 100%;

            .mat-card-title * {
                color: $card__header--color;
                font-size: 20px;
                font-weight: 700 !important;
                text-transform: uppercase;
                margin: 3px 0;
            }

            .pastille {
                display: none;
            }

        }

        // change l'apparence de la zone titre lorsqu'il y a le breadcrumb
        &.isDisplayLessonBreadcrumb mat-card-header {
            min-height: auto;
            padding: 12px 20px;
            background-color: $white-c;
            box-shadow: 0px 6px 15px -7px rgba(0, 0, 0, 0.14);

            .mat-card-header-text {
                margin: 0;
            }

            .mat-card-title * {
                margin: 0;
                text-transform: inherit;
                color: $card__header--background-color;
            }
        }

        .mat-card-image, .card-cover.videoUrl, .card-cover.image, .card-cover {
            width: 226px;
            min-width: 226px;
            max-width: 226px !important;
            height: auto;

            padding: 0;
            position: relative;

            left: inherit; // overide bad default css
            transform: none; // overide bad default css
        }
        .card-cover {
            height: 226px;
        }

        .mat-card-content {
            min-height: 100px;
            font-family: #{var(--typo1)}, #{var(--typo2)}, sans-serif;
            font-size: $card-content--font-size;
            font-weight: $card-content--font-weight;
            line-height: $card-content--line-height;
            text-transform: uppercase;
            color: $default-font-color;
            margin-left: 0;
            flex: 1;
            flex-direction: column !important;
            padding: #{$card-content--padding}; // 35px 18px 0;
            // max-height: 170px;
            background: #{$card-content--background};

            max-height: 226px;
            height: auto;

            .description-field {
                text-transform: none;
                padding: #{$card-content-short-description--padding};

                dt {
                    display: none;
                }

                dd {
                    color: var(--anthracite);
                }
            }

            dd {
                font-weight: 400;
                margin: 0;

                &:before {
                    content: ' ';
                    width: 1px;
                    display: block;
                    height: 1em;
                    float: left;
                }
            }
            // in some instance we want to present data in a bubble (educationnalLevel for example)
            // but inside an innerHtml element we use this css in this case to have a bubble replace the default presentation
            @if $card-content__field-content--bubble-format == true {
                dd.field-content {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    flex-wrap: wrap;

                    span {
                        height: auto;
                        background-color: var(--secondary-c);
                        color: white;
                        transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
                        display: inline-flex;
                        padding: 7px 12px;
                        border-radius: 16px;
                        align-items: center;
                        cursor: default;
                        min-height: 32px;
                        margin: 4px;
                    }
                }

                dd.field-content span.comma {
                    display: none;
                }

                dt.field-title {
                    display: none;
                }
            }

            .get-date-label dt {
                display: block;
            }

            .dl-list {
                flex: 0 !important;
                margin: 0;
            }

        }

        .mat-card-actions {
            //margin-top: -63px !important;
            //margin-left: 226px;
            flex: 1 1 100%;
            margin: #{$card-action--margin};
            padding: #{$card-action--padding};
            border: 1px solid rgba(203, 209, 219, 1) !important;


            #play-as-icon {
                order: 10; // last position
                mat-icon {
                    display: block;
                }
            }

            #button-assign-icon {
                margin-left: auto;

            }

            button {
                @extend %button--square-icon;

                &.mat-raised-button {
                    width: auto;
                }

                &.play-preview {
                    margin-right: auto;
                }
            }
        }
    }
}

/* ******************************** */
mat-card.mat-card mat-card-actions.mat-card-actions {
    .mat-card-actions__btn-bookmark, .lesson-page__btn-bookmark, .button-favorite {
        margin-left: auto;

        &.flag .mat-icon {
            color: var(--accent);

            path {
                fill: transparent;
            }
        }

        &.flag.active .mat-icon {
            path {
                fill: #E62716;
            }
        }

        &.flag.active .mat-icon {
            color: var(--accent);

            path {
                fill: $card-action__mat-icon--fill-color;
            }
        }
    }

    .mat-card-actions__btn-menu-trigger,
    .mat-card-actions__btn-bookmark,
    .button-favorite
    {
        @extend %button--inverted;
    }

    .mat-card-actions__btn-menu-trigger {
        padding: 0 10px;
    }
}

.lessons mat-card.mat-card mat-card-actions.mat-card-actions {
    .mat-card-actions__btn-menu-trigger,
    .mat-card-actions__btn-play-lesson {
        .mat-button-wrapper {
            flex-direction: row-reverse;
            justify-content: space-between;

            .mat-icon {
                margin-left: 10px;
            }
        }
    }

    .mat-card-actions__btn-bookmark {
        margin-left: auto;

        &.flag .mat-icon {
            color: var(--accent);

            path {
                fill: transparent;
            }
        }

        &.flag.active .mat-icon {
            path {
                fill: #E62716;
            }
        }

        &.flag.active .mat-icon {
            color: var(--accent);

            path {
                fill: $card-action__mat-icon--fill-color;
            }
        }
    }

    .mat-card-actions__btn-menu-trigger,
    .mat-card-actions__btn-bookmark
    {
        @extend %button--inverted;
    }

    .mat-card-actions__btn-menu-trigger {
        padding: 0 10px;
    }

    .mat-raised-button {
        line-height: 32px;
    }
}

/*
 *      overlay
 */

.card-split-media-info .mat-card.mat-card::after {
    display: none;
}
// should be remove forme default :nauseated_face: