%mat-tab-label {

}
%mat-ink-bar {

}


mat-tab-nav-panel {
    flex: 1;
    display: flex;
}

mat-tab-group.mat-primary { // classe .mat-primary par default du composant
        flex: 1;

        mat-tab-header.mat-tab-header {
            .mat-tab-label-container {

            }

        }

        div.mat-tab-body-wrapper {

            mat-tab-body.mat-tab-body {

                div.mat-tab-body-content {
                    display: flex; // s'ajuste au parent
                    flex-direction: column;
                }

            }
        }


    .mat-tab-nav-bar, .mat-tab-header {
        border: none;
    }


    &.mat-tab-group .mat-ink-bar,
    .mat-tab-nav-bar .mat-ink-bar {
        background-color: $tabs__ink-bar--background-color;
    }

    .mat-tab-label {
        justify-content: center;
        align-items: flex-end;

        .mat-tab-label-content {
            padding-bottom: 5px;
        }
    }

   &.mat-tab-group .mat-tab-label {
        background-color: transparent;

        /* H3 Bold 18 */


        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */

        text-align: center;

        /* anthracite */

        color: #323A49;
    }

}

.mat-tab-nav-bar.mat-primary {
    .mat-tab-links {
        justify-content: center;

        .mat-tab-link {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;

            color: #323A49;
        }
    }

    .mat-ink-bar {
        background-color: $tabs__ink-bar--background-color;
    }
}

