@use '@angular/material' as mat;
.mat-chip-list-wrapper {
    margin-bottom: 10px;
}
.mat-chip.mat-standard-chip {
    background-color: $chip--background-color;
    color: $chip--color;
    height: auto;

    .mat-icon {
        background-color: white;
        opacity: 1;
        color: #fcb731;
        svg {
            transform: scale(0.5);
        }
    }

}