@use '@angular/material' as mat;
 licensing-method-add, app-licensing-display {

    mat-expansion-panel {
        &.mat-expansion-panel {
            font-size: 18px;
            text-align: center;
            background: none;

            &:not([class*=mat-elevation-z]) {
                box-shadow: none;
            }
        }

        .mat-form-field {
            width: 100%;
            .mat-form-field-wrapper {
                width: 250px;
                margin: 0 auto;

                .mat-form-field, .mat-form-field-label {
                    // text-align: center; // TODO centrer le label et l'animé
                }
            }
        }

        button {
            &.mat-raised-button {
                background-color: mat.get-color-from-palette($primary);
                color: white;
            }
        }

        mat-expansion-panel-header {
            font-weight: bold;

            .mat-icon {
                width: 16px;
                height: 16px;
                min-width: 16px;
                max-height: 16px;
            }
        }

        .text-success {
            position: relative;
            padding: 40px 80px;

            p {
                margin: 0;
            }

            .pastille {
                height: 48px;
                width: 48px;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-26px);
                background-color: mat.get-color-from-palette($accent);
            }
        }
    }
    td {
        &.warning {
            color: mat.get-color-from-palette($accent);
        }
    }
}
