@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-thin.ttf") format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-thinitalic.ttf") format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-lightitalic.ttf") format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
   font-family: '#{$typography-font-name}';
   src: font("#{$typography-font-name}/#{$typography-font-name}-regular.ttf") format('truetype');
   font-weight: 400;
   font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-italic.ttf") format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
   font-family: '#{$typography-font-name}';
   src: font("#{$typography-font-name}/#{$typography-font-name}-medium.ttf") format('truetype');
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: '#{$typography-font-name}';
   src: font("#{$typography-font-name}/#{$typography-font-name}-mediumitalic.ttf") format('truetype');
   font-weight: 500;
   font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-bolditalic.ttf") format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-black.ttf") format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: '#{$typography-font-name}';
    src: font("#{$typography-font-name}/#{$typography-font-name}-blackitalic.ttf") format('truetype');
    font-weight: 900;
    font-style: italic;
}