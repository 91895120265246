.medias, .media-wrapper { // parent des media-wrapper ci-dessous  .bem-multimedia__media
    overflow: auto !important;
}

// MEDIA/IMAGE LEGENDE
.medias.bem-multimedia__media {
    // fxLayout
    position: relative;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @include media-breakpoint-up('sm') {
        flex-direction: row;
        @include g-2;
    }

    .media-wrapper {
        max-width: min(90%, 100rem) !important;


        &:not(.pdf-download) {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            @include r-2;
        }

        &:not(.pdf-download):not(.audio) {
            @include wrapper--tertiaire--strocked--raised;
        }

        &.text {
            max-height: calc(100vh - 30rem);
            @include py-2;
            @include px-3;
            font-size: #{set-fluid-typo(16px, 20px)};
        }

        &.image {
            display: block;
            @include p-0;
            width: 80vh;

            #imgToZoom, & > div, [id="#imgToZoom*"], {
                display: block;
                height: 100%;
                // div.action-buttons { img {} } // div inutile
                img {
                    display: block;
                    object-fit: contain;
                    max-height: 50vh; // pas convaincu
                    margin: 0 auto;

                    @media screen and (max-height: 600px) {
                        max-height: 30vh; // pas convaincu
                    }
                }
            }
        }

        &.audio {
            @extend %answer;
            flex: none;
            @include g-3;
            width: 35rem;
            min-width: min-content;
            height: auto;
            aspect-ratio: unset;
            @include p-2;

            .multimedia__audio-icon-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                @include g-3;
                @include mt-3;
                @include mx-3;

                .mat-icon {
                    flex: 0 1 auto;
                    height: 6rem;
                    width: auto;

                    svg {
                        height: 100%;
                        width: auto;
                    }
                }
            }

            audio {
                @extend %button--radius;
                display: block;
                width: 100%; // Required for Safari based browser styling
                height: 4rem; // Required for Chrome based browser styling
            }
        }

        &.pdf-download {
            height: 100%;
        }
    }
}