@use '@angular/material' as mat;
// stepper-wrapper.html charge en contenu lessons.component.html

// Player lesson header
.bem-app-stepper-wrapper__header {
    // background-color: #4caf50;
    display: flex;
    padding: 0 var(--padding-lateral-viewport);
}

.bem-app-stepper-wrapper__header {

    /* anthracite */
    background: var(--color-lessons-header);
    /* Gris de contour */
    border: 0.5px solid var(--gris_de_contour);



    button {
        padding: var(--stepper__button-padding);
    }

    // wrapper HTML inutile
    > div { // .header-top
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        @extend %py-2;
    }

    .fuse-app-lessons__button-help {
        order: 2;
        margin-left: auto;
    }
    .bem-fuse-app-lessons__title {

    }

    .toolbar {
        flex-direction: row !important;
        flex: 1;
        .fuse-app-lessons__button-tools {
            margin-right: auto;
        }
        .fuse-app-lessons__button-fullscreen {
            margin-left: auto;
        }
    }

}