.mat-toolbar-multiple-rows.filters {
    min-height: inherit;
}

body.player toolbar.top-main-toolbar {
    display: none;
}

.top-main-toolbar .btn__goToLoginPage {
    color: $main-toolbar__button__btn__goToLoginPage--color;
}

.top-main-toolbar .user-button {
    color: $main-toolbar__user-button--color;

    mat-icon {
        color: $main-toolbar__user-button--color;
    }
}