@import "../../../shared_theme/abstracts/path-function";



%star_on {
    background: transparent icon('star_on.svg') no-repeat center center;
}
%star_off {
    background: transparent icon('star_off.svg') no-repeat center center;
}

%radio_on {
    background: transparent icon('radio_on.svg') no-repeat center center;
}
%radio_off {
    background: transparent icon('radio_off.svg') no-repeat center center;
}
%question_text {
    font-weight: bold;
    color: #3A4354;
    font-size: 16px;
    padding: 0;
    margin: 0 0 10px 0;
}

.feedback-dialog {  // overlay
    display: flex;
    flex-direction: column;

    mat-dialog-container.mat-dialog-container {
        display: flex;
        flex-direction: column;

        app-feedback-dialog { // popup
            display: flex;
            flex-direction: column;
            flex: 1;

            .mat-dialog-title {
                flex: none;
                margin-bottom: 0;
            }
            .mat-dialog-content {
                display: flex;
                justify-content: flex-start;
                padding: 0;

                form {
                    flex: 1;
                    display: flex;
                    justify-content: space-around;
                    padding-top: 20px;

                    > div {
                        padding: 0 20px 20px 20px;
                    }
                }
            }
        }
    }
}

app-feedback-dialog{
    mat-form-field{
        width: 100%;
    }

    .mat-form-field-infix {
        width: 100%;
    }
}

.mat-dialog-content {
    div {
        p {
            @extend %question_text;
        }
    }

    .rating {
        display: flex;
        width: 100%;
        justify-content: center;
        overflow: hidden;
        flex-direction: row-reverse;
        position: relative;

        > input {
            display: none;

            &:checked ~ label,
            &:checked ~ label ~ label {
                @extend %star_on;
            }

            &:not(:checked) ~ label:hover,
            &:not(:checked) ~ label:hover ~ label {
                @extend %star_on;
            }
        }

        > label {
            cursor: pointer;
            width: 40px;
            height: 40px;
            margin-top: auto;
            @extend %star_off;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 76%;
            transition: 0.3s;
        }

        .rating-0 {
            filter: grayscale(100%);
        }
    }

    .slider-container {
        width: 50%;
        margin: 0 auto;

        mat-slider {
            width: 100%;
            height: 28px;

            &.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb,
            &.mat-slider:not(.mat-slider-disabled):not(.mat-slider-sliding) .mat-slider-thumb-label,
            &.mat-slider:not(.mat-slider-disabled):not(.mat-slider-sliding) .mat-slider-thumb,
            &.mat-accent .mat-slider-track-fill, &.mat-accent .mat-slider-thumb, &  .mat-accent .mat-slider-thumb-label
            {
                bottom: -12px;
                transform: scale(1);
                background-color: $accent-c;
                border: 1px solid rgba(0, 0, 0, 0.05);
                box-shadow: 0 0 2px rgba(0, 0, 0, 0.25), inset 0 2px 4px rgba(255, 255, 255, 0.25);
            }

            .mat-slider-wrapper {
                padding-top: calc(100% * 0.02);
                height: auto;
                top: 2px;
                left: 50%;
                right: 0;
                width: 95%;
                transform: translateX(-50%);

                .mat-slider-track-wrapper {
                    background-repeat: no-repeat;
                    height: auto;
                    padding-top: calc(100% / 50);
                    background-color: #f5f5F5;
                    box-shadow: inset 0 2px 5px rgb(0 0 0 / 20%);
                    border-radius: 1em;

                    .mat-slider-track-fill {}
                    .mat-slider-track-background
                    {
                        display: none;
                    }
                    &:after { // last ticks
                        opacity: 1;
                        height: 10px;
                        top: 10px;
                    }

                }

                .mat-slider-ticks-container {
                    // display: none;
                    opacity: 1;
                    height: 10px;
                    top: 10px;
                    .mat-slider-ticks {
                        height: 10px;
                        opacity: 1;
                        padding-left: inherit;
                    }
                }

                .mat-slider-thumb-container {
                    .mat-slider-focus-ring {}
                    .mat-slider-thumb {
                        bottom: -8px;
                    }
                    .mat-slider-thumb-label {}
                }

            }


        }

        ol {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: -1rem;
            list-style: none;
            padding: 0;
            margin-bottom: 0;

            li {
                position: relative;
                padding-top: 10px;
                &::before {
                    content: '';
                    height: 10px;
                    width: 2px;
                    display: block;
                    background: $primary-c;
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);
                    position: absolute;
                }
            }
        }
    }

    fieldset {
        border: none;
        display: flex;
        justify-content: center;
        column-gap: 1.5rem;

        legend {
            @extend %question_text;
            
            display: flex;
            flex-direction: column;
            width: 100%;
            
            pre {
                align-self: center;
                padding: 0 10px;
                width: 450px;
                border: 1px solid black;
            }
        }
        label {
            color: #3A4354;
        }

        > div {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        input[type="radio"] {
            position: absolute;
            opacity: 0;
        }
        label {
            padding-top: 25px;
            text-align: center;
        }
        label:before, label:after {
            content: '';
            position: absolute;
            display: block;
            width: 20px;
            height: 20px;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
        label:after {
            @extend %radio_off;
            z-index: 1;
        }
        label:before {
            // @extend %radio_on;
        }
        input:checked + label:before, label:hover:before, label:focus:before {
            @extend %radio_on;
            z-index: 2;
        }
    }

    .mat-dialog-actions {
        padding-top: 0;
    }

}
