app-image-zoning-play-sound {
    @include app-image-zoning;

    .activities-card {

        .mat-card-content {

            .available-answers {

                .container {

                    &.false-answer {
                        border: #{$image-zoning-play-sound__available-answers__container__false-answer--border} !important;
                        border-radius: 2px !important;
                    }

                    &.true-answer {
                        border: #{$image-zoning-play-sound__available-answers__container__true-answer--border} !important;
                        border-radius: 2px !important;
                    }

                    > div {

                        img {
                            border-radius: 0 !important;
                        }
                    }

                    app-zone-generator {

                        .generated-column {

                            .generated-row-element {

                                .colored-zone {

                                    &:active {
                                        background-color: transparent !important;
                                        opacity: 1 !important;
                                    }
                                }

                                .selected-true {
                                    border: #{$image-zoning__selected-true-border};
                                    border-radius: 2px !important;
                                    background-color: transparent !important;
                                }

                                .selected-false {

                                    &:hover {
                                        cursor: default !important;
                                    }
                                }

                                .answer-was-selected-almost-once {
                                    border: #{$image-zoning__answer-was-selected-almost-once--border};
                                    border-radius: 2px !important;
                                }

                                // Playing sound in progress
                                .currently-correct {
                                    border: #{$image-zoning__currently-correct--border};
                                    border-radius: 2px !important;
                                }
                            }
                        }
                    }
                }

                audio {
                    visibility: hidden;
                }
            }
        }
    }
}