@use '@angular/material' as mat;

/* SUMMARY
*
*   CUSTOM VALUES
        colors figma
        colors answers status
        Global generics variables colors
        logo
        Typographie
        Links
        icon color

*   ****** CORE ***** //1
*   BASIC PAGE
*   LESSON PAGE
*   CARDS
*   MENU PANEL
*   TOP MAIN TOOLBAR
*   DIALOG MODALES
*   SCROLLBAR
*   BUTTON
*   ACTIVITIES BUTTON
*   FORM FIELDS
*   GROUPS MANAGEMENT
*   NAVBAR SIDEBAR
*   TABLE
*   TABLE
*   PAGINATOR
*   APP-IMAGE-ZONING APP-IMAGE-ZONING-PLAY-SOUND
*   ******* MODULES ***** //2
*   ACTIVITIES
*   AUTHENTICATION
*   CORPUS
*   GRAPH MATHIA
*   PROGRESS BAR
*   PROGRESS MULTI ZONE
*   KEYBOARD LATEX
*   BODY
*   MEMORY
 */

/* ******************************* CUSTOM VALUES ****************************************/

// Call colors figma with var(--<nom variable>) everywhere
/*

    Figma > Bayard > Design systheme > Couleur

*/

$typography-font-name: 'lato';
$typography-font-name2: 'lato';
$font-family: '#{$typography-font-name}, "Helvetica Neue", Arial, sans-serif';
$font-family2: '#{$typography-font-name2}, "Helvetica Neue", Arial, sans-serif';

// Typography
$root-em: 10px; // Root element font-size
$default-font-color: var(--text);
$default-font-contrast-color: var(--primarylabel);

// Spacing (fluid scale calculator: https://www.fluid-type-scale.com)
$spacing-0: 0;
$spacing-1: clamp(5px, 0.38vw + 2.73px, 10px); // 5px to 10px
$spacing-2: clamp(10px, 0.76vw + 5.45px, 20px); // 10px to 20px
$spacing-3: clamp(20px, 1.52vw + 10.91px, 40px); // 20px to 40px
$spacing-4: clamp(40px, 3.03vw + 21.82px, 80px); // 40px to 80px
$spacing-5: clamp(80px, 6.06vw + 43.64px, 160px); // 80px to 160px
$spacing-auto: auto;

$spacing-2neg: clamp(-10px, 0.76vw + 5.45px, -20px); // -10px to -20px


$radius-0: 0;
$radius-1: clamp(8px, 0.38vw + 2.73px, 12px); // 8px to 12px
$radius-2: clamp(12px, 0.76vw + 5.45px, 26px); // 12px to 26px
$radius-3: clamp(16px, 1.52vw + 10.91px, 24px); // 16px to 24px
$radius-4: clamp(24px, 3.03vw + 21.82px, 32px); // 24px to 32px
$radius-5: clamp(32px, 6.06vw + 43.64px, 64px); // 32px to 64px
$radius-rounded: 50%;

:root {

    --typo1: #{$typography-font-name};
    --typo2: #{$typography-font-name2};

    --bj-vert-ok: #89C52B; // fond boutons ok
    --bj-vert-ok-shadow: #659815; // bordures boutons ok
    --Gris_bouton: #F4F4F4;
    --bj-gris-clair: #EBEBEB;
    --bje-vert: #5DD3D1;
    --bje-jaune: #FFED00;
    --bje-jaune-shadow: #E4C002;
    --bje-rouge: #FF0000;
    --milan-vert: #37C6C3;
    --milan-vert-fonce: #146A68;

    --noir: #000000;
    --blanc: #FFFFFF;

    --faux-blanc: #F9FBFF;
    --gris-de-fond: #E8EDF6;
    --gris-de-contour: #CBD1DB;
    --noir_5: rgba(0, 0, 0, 0.05);
    --noir_10: rgba(0, 0, 0, 0.1);

    --orange: #FF790D; // complémentaire partagé avec le vert (kuler)
    --vert: #009944; // barre de progression maquette
    --vert2: #016B3E; //

    /*
    *   colors answers status
    *
    *   - pristine : Intouché donc disponible (parfaite) - rouge couleur par default des maquettes
    *   - currently-correct :  effet précédent le passage à "was-correct"  > currently-correct = was-correct
    *   - was-correct : BON
    *   - incorrect : Faux - noir
    *   - missing : manquant - fait parti des bonnes réponses mais pas selectionnées genre qcm
    *   - RIGHT ( de latex keyboard ) => was-correct
    *   - WRONG ( de latex keyboard ) => incorrect
    *   - VALIDATED ( de latex keyboard )  Ca a été enregistré (eboost) bouton à côté du champs donne la possibilité de modifier et enrg
    *   - DISABLED ( tu dois attendre ) ou bon et plus selectionnable
    *
    */


    --accent: #{map_get($md-accent, 500)};
    --accent-border: var(--bje-jaune-shadow); // darken( map_get($md-accent, 500), 30%);
    --accent-color: $default-font-color; // darken( map_get($md-accent, 500), 30%);

    --pristine: var(--milan-vert);
    --pristine-border: var(--milan-vert-fonce);
    --pristine-color: var(--blanc);
    --currently-correct: var(--bj-vert-ok);
    --currently-correct-border: var(--bj-vert-ok-shadow);
    --currently-correct-color: var(--blanc);
    --was-correct: var(--bj-vert-ok);
    --was-correct-border: var(--bj-vert-ok-shadow);
    --was-correct-color: var(--blanc);
    --was-correct-shadow: var(--vert2);
    --incorrect: var(--noir);
    --incorrect-border: #484848;
    --incorrect-color: var(--blanc);
    --incorrect-shadow: var(--noir);
    --right: var(--noir);
    --right-border: var(--noir);
    --right-color: var(--blanc);
    --wrong: var(--bje-rouge);
    --wrong-border: var(--bje-rouge);
    --wrong-color: var(--blanc);
    --validated: var(--bj-vert-ok);
    --validated-border: var(--bj-vert-ok);
    --validated-color: var(--blanc);
    --missing: var(--blanc);
    --missing-border: var(--blanc);
    --missing-color: var(--noir);
    --missing-shadow: var(--bleu_tralalere_accessible);

    --disabled: var(--noir_10);
    --disabled-border: var(--noir_10);
    --disabled-color: var(--noir);
    --disabled-shadow: 'inset 0 -3px 0px #{var(--noir_10)}';

    --answer__button-shadow: 'none';

    --selected: var(--milan-vert);
    --selected-border: var(--milan-vert);
    --selected-color: var(--milan-vert);
}

:root {
    /*
    *
    *   scss variables to css variables
    *
     */

    --secondary-c: var(--milan-vert);
    --primary-c: map_get($mat-fusedark, 500);
    --accent-c: map_get($md-accent, 500);
    --black-c: black;
    --white-c: white;
    --dark_grey: map_get($mat-fusedark, 500);
}

/*
*  Global generics variables colors
*
*   color direct pour changement automatique pour une transformation du theme
*
 */
$secondary-c: var(--milan-vert);
$primary-c: map_get($mat-fusedark, 500);
$accent-c: map_get($md-accent, 500);
$black-c: black;
$white-c: white;
$dark_grey: map_get($mat-fusedark, 500);


%icon-generic-size {
    font-size: 24px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    line-height: 24px;
}


// logo
[svgicon="brand"] { // sidebar
    .brand-background {
        fill: map_get($mat-fusedark, 500); //primary
    }
}

[svgicon="brand_square"] { // login
    .brand-text-ia {
        color: map_get($mat-fusedark, 500); //primary
    }

    .brand-background {
        fill: map_get($mat-white, 500); //primary
    }
}

// Typographie
$default-font-color: black;
$default-font-contrast-color: white;
$default-font-size: 14px;
$default-font-weight: 400;
$default-line-height: 1.4;


// Links
fuse-partial-typography, a { // overide src/@fuse/scss/partials/_typography.scss
    color: $default-font-color;
}

// icon
$mat-icon-default-color: map_get($mat-black, 500);
$mat-icon-default-contrast-color: map_get($mat-white, 500);

/* *********************************************************************************/
/* ********** CORE *********** CORE *************** CORE **************** CORE ******/

/* ******************************* BASIC PAGE ****************************************/
$basic-page--color: $default-font-color;
$basic-page__icon--background-color: var(--milan-vert);
$basic-page__li--background-color: $accent-c;
$basic-page__link--color: $default-font-color;
$basic-page__link--text-decoration: underline;

/* ******************************* LESSON PAGE ****************************************/
$lesson-page__lesson-section--border: 1px solid var(--gris-de-contour);
$lesson-page__generic-element--background-color: var(--bj-gris-clair);
$lesson-page__file-card--color: $default-font-color;

/* ******************************* CARDS ****************************************/
$card__header--color: $default-font-color;
$card__header--background-color: $secondary-c;
$card__header--breadcrumb--background-color: white;
$card__header--breadcrumb--color: $secondary-c;
$card-content--padding: '15px 18px 0';
$card-content--background: var(--faux-blanc);
$card-content--font-size: $default-font-size;
$card-content--font-weight: $default-font-weight;
$card-content--line-height: $default-line-height;

$card-content__field-content--bubble-format: true; // concern some fields like educationnalLevel to present in bubble format

$card-content-short-description--padding: '0 0 10px 0';

$card-action--margin: '0 !important'; // '-63px 0 0 226px !important';
$card-action--padding: '8px 16px !important'; // '8px 16px !important';
$card-action__mat-icon--fill-color: black;
$card-action__mat-menu-panel__mat-icon--color: black;

$fake-card--background-color: $accent-c;
$fake-card--color: $default-font-color;

/* ******************************* MENU PANEL ****************************************/
$menu-panel--font: 700 #{$default-font-size}/#{$default-line-height} #{var(--typo1)}, #{var(--typo2)}, sans-serif;

$menu-panel__button--background-color: transparent;
$menu-panel__button--color: $default-font-color;

/* ******************************* TOP MAIN TOOLBAR ****************************************/
$main-toolbar__user-button--color: $default-font-color;

/* ******************************* BANNER INFO ****************************************/
$banner-info__mat-toolbar--line-height: $default-line-height;
$banner-info__mat-toolbar__paragraph--font-weight: $default-font-weight;

/* ******************************* DIALOG MODALES ****************************************/
$dialog__link--color: $default-font-color;
$dialog__link--text-decoration: underline;

$dialog__submit-button--disabled--color: $default-font-color;

$dialog__title--background-color: $secondary-c;
$dialog__title--color: $default-font-color;

/* ******************************* TABS ****************************************/
$tabs__ink-bar--background-color: var(--bje-rouge);

/* ******************************* SCROLLBAR ****************************************/
$perfectscrollbar-default-background: mat.get-color-from-palette($accent);
$perfectscrollbar-hover-background: $perfectscrollbar-default-background;
$perfectscrollbar-hover-width: 11px;

/* ******************************* CHIPS ****************************************/
$chip--background-color: var(--accent);
$chip--color: $default-font-color;

$standard-chip--background-color: var(--milan-vert);
$standard-chip--color: $default-font-contrast-color;

/* ******************************* BUTTON ****************************************/
$button--accent-color: $default-font-color;
$button--primary-color: $default-font-color;

$button-inverted--color: var(--noir);
$button-inverted--background-color: var(--blanc);

$button--accent--border: '1px solid #{var(--accent-border)}';
$button--accent--box-shadow: '0px 4px 10px rgba(0, 0, 0, 0.25)';
$button--accent--border-radius: '4px';
$button--accent--font-weight: '700';
$button--accent--size: $default-font-size;

$button--actif--color: $default-font-contrast-color;
$button--actif--background-color: $secondary-c;

$mat-button-base-extend: null;
$mat-raised-button-extend: '%button--accent';

$button-mat-fab-background-color: $accent-c;
$button-mat-fab-dimension: 65px;

$button-mat-fab-stroke-color: map_get($accent, 500);

$button__flag--color: $default-font-color;
$button__flag--hover--color: transparent;
$button__flag--active--color: $default-font-color; // var(--bje-rouge); // white

$button__mat-menu-trigger--extend: '%button--only-icon'; // null ou Nom Placholder
$button__mat-menu-trigger--color: $default-font-color; // var(--bje-rouge); // white

$image__button-fullscreen--border-radius: '10px';
$image__button-fullscreen--padding: '2px 3px 0 2px';
$image__button-fullscreen--color: $default-font-color;
$image__button-fullscreen--background-color: var(--accent);
$image__button-fullscreen--height: 40px;
$image__button-fullscreen--border: '2px solid #{var(--accent-border)}';

/* ******************************* ACTIVITIES BUTTON ****************************************/
:root {
    --stepper__button-padding: 8px 10px;
}

$activities__button--color: $default-font-color; //var(--blanc)
$activities__button--background-color: var(--blanc);
$activities__button--border--width: 2px;
$activities__button--border: '#{$activities__button--border--width} solid #{$secondary-c}'; // 2px solid rgba(0, 0, 0, 0.1);
$activities__button--box-shadow: '0 3px 0 #{$secondary-c}';
$activities__button--box-shadow--disabled: 'inset 0 -3px 0px #{$secondary-c}';
$activities__button--line-height: 14px;

$activities__button-selected--color: var(--blanc);
$activities__button-selected--background-color: var(--selected);

$activities__button__sound--border: '2px solid var(--bje-jaune)'; // 2px solid #CBD1DB

$activities__button-accent--border: $activities__button--border--width solid var(--accent-border);
$activities__button-accent--box-shadow: 0 3px 0 #{var(--accent-border)};

$activities__app-flashcard__overlay--background-color: var(--milan-vert);

// answers image
$available-answers__image-feedback--border-radius: 18px;
$available-answers__image-feedback--border-width: 4px;
$available-answers__button__image-extend: '%extra-img-effect'; // '%feedbackBorderButton';

$app-flashcard__button-image-extend: '%extra-img-effect';

$app-what-is-missing-audio__btn-select-item--color: black;

/* ******************************* FILTERS ****************************************/
$filter-extend: '%filters_bgb_bdg_z2';

$filter__button--disabled--color: $default-font-color;
$filter__button-collapse--border-color: $default-font-color;
$filter__button-collapse--mat-icon--color: $default-font-color;
$filter__button-collapse--mat-icon--border-color: transparent;

/* ******************************* FORM FIELDS ****************************************/
$form-field-label-font-size: '14px';
$form-field-input-color: $default-font-color;
$form-field-input-label-color: $default-font-color;
$form-field-input__label-float--color: $default-font-color;
$form-field-input-label-color-focused: $default-font-contrast-color;
$default-input-height: 30px;
$form-field-input-font: 500 #{$default-font-size}/36px "#{var(--typo1)}", sans-serif;
$default-input-line-height: $default-input-height;
$form-field-label-font: 500 #{$form-field-label-font-size}/16px "#{var(--typo1)}", sans-serif;
$form-field-label-text-transform: "uppercase";
$form-field-label-top: 0; // -8px;
$form-field-background-color: rgba(0, 0, 0, 0.12);
$form-field-underline-background-color: rgba(0, 0, 0, 0.4);
$form-field-underline-bottom: 10px;

$form-field-flex-border: 1px solid $default-font-color;
$form-field-flex-border-top: none;
$form-field-flex-border-bottom: none;
$form-field-flex-border-left: none;
$form-field-flex-border-right: none;
$form-field-ripple-background: mat.get-color-from-palette($accent);
$form-field-infix-padding: '0 5px 5px 5px';
$form-field-infix-line-height: 2em;
$form-field-margin-bottom: 0.5em;
$form-field-select-panel-background-color: map_get($mat-white, 500);


$mat-form-field-checkbox-color: rgba(0, 0, 0, 0.5);
$mat-form-field-checkbox-background-color: transparent;
$mat-form-field-checkbox-checkmark-stroke-color: var(--bje-rouge);
$mat-form-field-checkbox-border: '2px solid #{var(--bje-rouge)}';
$mat-form-field-checkbox-border-radius: 0;

$mat-form-field-checkbox-color-checked: mat.get-color-from-palette($accent);
;
$mat-form-field-checkbox-background-color-checked: mat.get-color-from-palette($accent);

$mat-form-field-option-color: rgba(0, 0, 0, 0.5);

$mat-form-field__select-arrow--color: var(--bje-rouge);

/* ---------------- Activities Form field -------------------- */
$activities-field-checkbox-width: '18px';
$activities-field-checkbox-height: '18px';
$activities-field-checkbox-margin-right: '13px';
$activities-field-checkbox-background-color: transparent;
$activities-field-checkbox-checkmark-stroke-color: var(--milan-vert);
$activities-field-checkbox-border: '3px solid #{var(--milan-vert)}';
$activities-field-checkbox-border-radius: 3px;
// checked
$activities-field-checkbox-checked-border-color: '2px solid #{var(--pristine-border)}';
$activities-field-checkbox-checked-checkmark-box-shadow: 'none';
$activities__field__checkbox-checked__checkmark--transform: 'scale(1.1)';


/* ******************************* GROUPS MANAGEMENT ****************************************/
$groups-inline__button-add--background-color: $accent-c;
$groups-inline__button-add--color: $default-font-color;

/* ******************************* NAVBAR SIDEBAR ****************************************/
/* *** navbar-header *** */
// Couleur de fond
$navbar-content-background-color: $primary-c;
$navbar-header-background-color: 'transparent';
$navbar-header-box-shadow: 'none';
$navbar-header-icon-dimensions: 32px;
$navbar-header-icon-svg-color: map_get($mat-white, 500);

// Couleur de fond du sous menu ouvert
$nav-collapsable--background: #{var(--milan-vert)};
// $nav-collapsable--background: 'red';

// Dimension du logo
$logo-icon-svg-color: mat.get-color-from-palette($mat-white, 500);
$logo-icon-svg-width: 158px;
$logo-icon-svg-height: 38px;

// Items
$navbar-vertical__item-external-url--margin-top: '0';
$navbar-vertical__item-mentions-legales--margin-top: 'auto';

// Couleurs des élements textuels et icones
$nav-font-color: $default-font-contrast-color;
$nav-link-title-font-size: 1.6rem;
$nav-link-title-color: $default-font-color;
$nav-link-title-color-active: $default-font-color;
$nav-icon-color: $default-font-color;

$nav-link-icon-dimension: 26px;
$nav-link-icon-font-size: $nav-link-icon-dimension;
$nav-link-icon-width: $nav-link-icon-dimension;
$nav-link-icon-height: $nav-link-icon-dimension;
$nav-link-icon-width: $nav-link-icon-dimension;
$nav-link-icon-height: $nav-link-icon-dimension;
$nav-link-icon-line-height: $nav-link-icon-dimension;
$nav-link-icon-color-active: $default-font-color;
$nav-link-icon-color-hover: $nav-link-icon-color-active;
$nav-link--hover--background-color: $secondary-c;
$nav-link--active--background-color: $nav-link--hover--background-color;
$nav-link__collapse-open--active--background-color: $nav-link--hover--background-color;
$use_button_app_with_img: false;

/* ******************************* TABLE ****************************************/
$table-thead-background-color: map_get($primary, 500);
$table-thead-font-color: $default-font-color;
$table-thead-checkbox-color: $default-font-contrast-color;
$table-thead-border: 1px solid rgba(map_get($primary, 500), 0.5);
$table-thead-border-radius: 5px;
$table__thead--font-size: $default-font-size;
$table__mat-cell--color: $default-font-color;
$table__mat-cell--SelectedAndHover--color: $default-font-contrast-color;
$table-icon-stroke-color: map_get($accent, 500);
$table-icon-stroke-color-hover: map_get($accent, 300);
$table-icon-stroke-inactive-color: #C4C4C4;
$table__button__icon--color: var(--bje-rouge);
$table__tbody__row--background-color: $secondary-c;

/* ******************************* PAGINATOR ****************************************/
$paginator__button--color: $secondary-c;

/* ******************************* APP-IMAGE-ZONING and APP-IMAGE-ZONING-PLAY-SOUND***********/
$image-zoning__selected-true-border: '2px solid transparent';
$image-zoning__selected-true-background: '#{var(--was-correct)}';

$image-zoning__currently-correct--border: none;
$image-zoning__available-answers__container--border: 'none';
$image-zoning__answer-was-selected-almost-once--border: none;

$image-zoning-play-sound__available-answers__container__true-answer--border: '2px solid #{$secondary-c}';
$image-zoning-play-sound__available-answers__container__false-answer--border: '2px solid #{$secondary-c}';

$image-zoning__available-answers__container__true-answer--border: '2px solid #{var(--currently-correct)}';
$image-zoning__available-answers__container__false-answer--border: '2px solid #{var(--incorrect)}';

/* *********************************************************************************/
/* ****** MODULES ********** MODULES *********** MODULES ************ MODULES ******/
//2


/* ******************************* ACTIVITIES ****************************************/
$fuse-app-lessons-header__title--color: lighten($black-c, 60%);

$consignes__icon--border: '2px solid var(--milan-vert)';
/* ** Generic stepper ** */
$stepper__button--color: $default-font-color;
$stepper__button--background-color: var(--blanc);
$stepper__button--box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)';

$stepper__button-hover--color: $default-font-color;
$stepper__button-hover--background-color: var(--milan-vert);
$stepper__button-hover--box-shadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)';

$stepper__button-active--color: var(--milan-vert); // var(--bje-jaune);
$stepper__button-active--background-color: var(--blanc);
$stepper__button-active--box-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)';

$stepper__button-current--color: $default-font-color;
$stepper__button-current--background-color: var(--milan-vert); // var(--bje-jaune);
$stepper__button-current--box-shadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)';

$stepper__mat-select-panel--background: 'white';
$stepper__mat-select-panel--border: 'thin solid e9e9e9;';
$stepper__mat-select-panel--color: #000000;
$stepper__mat-select-panel__span--color: black;
$stepper__mat-select--border: 'none';
$stepper__mat-select_span--color: black;
$stepper__mat-select-panel__generic_stepper_mat-select-panel--background-color: var(--milan-vert);
$stepper__mat-select-panel__generic_stepper_mat-select-panel__hover--background-color: '#bcf1f0';
$stepper__mat-select-panel--box-shadow: 'none';

$stepper__button-disabled--color: black;
$stepper__button-disabled--background-color: var(--bje-jaune);

/* ---------------- Activities Consignes -------------------- */
$consignes__wording-instruction--font-size: 18px;
$consignes__wording-instruction--line-height: 21px;
$activities__consignes__image--filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25))';
$activities__consignes__image--border: 'none';
$consignes__wording-instruction--color: $default-font-color;

/* ---------------- Answers selected -------------------- */
$answers-selected--border: none;
$answers-selected--elevation: null; // or null

/* ---------------- Answers available -------------------- */
$answers-available--gap: '10px';

/* ---------------- answer-position -------------------- */
$answer-position--background: var(--Gris_bouton); //$secondary-c;
$answer-position--border: none;
$answers-position--elevation: null; // number or null
$answer-position--current--background: white; // $accent-c;
$answer-position--current--color: $default-font-color;
$answer-position--current--border: '1px solid black';

/* ******************************* AUTHENTICATION ****************************************/
//fuse-login and register
$fuse-authentication-padding: '32px 32px 68px 32px ';

$fuse-authentication-color: $default-font-contrast-color;
$fuse-authentication-field-input-font: 500 14px/32px "#{var(--typo1)}", sans-serif;
$fuse-authentication-field-input-color: $default-font-color;
$form-field-label-height: 1em;
$fuse-authentication-border-radius: 20px;
$fuse-authentication-field-label-color: $default-font-contrast-color;
$fuse-authentication-field-infix-line-height: 32px; //'2em';
$fuse-authentication-field-infix-padding: '0 15px 0 15px';
$fuse-authentication-field-infix-border-radius: 5px;
$fuse-authentication-background: $secondary-c;

$fuse-authentication-link-decoration: "underline";
$fuse-authentication-title-font: 700 24px/28px "#{var(--typo1)}", sans-serif;
$fuse-authentication-title-letter-spacing: "0.25px";

$main-toolbar__button__btn__goToLoginPage--color: $default-font-color;

$fuse-authentication-button-width: 'auto';
$fuse-authentication-button-border-radius: '1.5em';
$fuse-authentication-button-margin: '0';
$fuse-authentication-button-padding: '14px 16px 11px 16px';
$fuse-authentication-button-radius: '1.5em';
$fuse-authentication-button-background: map-get($mat-white, 500);
$fuse-authentication-button-color: $default-font-color;
$fuse-authentication-button-font: 700 14px/16px "#{var(--typo1)}", sans-serif;
$fuse-authentication-button-text-transform: 'uppercase';

// login-form
$fuse-authentication-login-width: 483px;
$fuse-authentication-logo-width: 274px;
$fuse-authentication-logo-margin: '5px auto 0 auto';
$fuse-authentication-title-margin: '10px 0 10px 0';

// breadcrumb
$breadcrumb__ul__li--color: var(--milan-vert);

/* ******************************* CORPUS ****************************************/
$corpus__details-sidebar--background-color: $secondary-c;
$corpus__details-sidebar--color: $default-font-color;

$corpus__helper--empty--background-color: $accent-c;
$corpus__helper--empty--color: $default-font-color;

$corpus__table__row--selected--background-color: $secondary-c;
$corpus__table__row--selected-extension-line--background-color: $secondary-c;
$corpus__table__row--hover--color: $default-font-color;
$corpus__table__row--mat-icon--color: var(--bje-rouge);


/* ******************************* GRAPH MATHIA ****************************************/
$graph-mathia__progress-graph--background-color: $accent-c;
$graph__mat-card-header--color: black;

/* ******************************* PROGRESS BAR ****************************************/
$progress-bar-generic--background-color: $primary-c;
$progress-bar-generic__wrapper--background-color: white;
$progress-bar-generic__bar--background-color: $accent-c;

/* ******************************* PROGRESS MULTI ZONE ****************************************/
$progress-multi-zone--background-color: $primary-c;
$progress-multi-zone__bar--size: '30%';
$progress-multi-zone__cursor--display: 'none';

/* ******************************* KEYBOARD LATEX ****************************************/
$latex-keyboard__renderedInputContent--border: 'transparent'; // 2px solid mat.get-color-from-palette($primary);
$latex-keyboard__renderedInputContent--padding: '0 20px';
$latex-keyboard__latex-wrapper--background-color: 'white';
$latex-keyboard__latex-wrapper--border: '2px solid #{var(--noir)}';
$latex-keyboard__latex-wrapper--extend: null; // null ou nom du placeholder

/* ******************************* HOME PAGE ****************************************/
$app-home-page__card-content--font-size: $default-font-size;
$app-home-page__card-content--font-weight: $default-font-weight;
$app-home-page__card-content--line-height: $default-line-height;

$app-home-page__content__paragraph--font-size: $default-font-size;
$app-home-page__content__paragraph--line-height: 1.2;

$app-home-page__card-header--color: $default-font-color;

/* ******************************* BODY ****************************************/
$body--background-color: #FFFFFF;

/* ******************************* MEMORY ****************************************/
$app-memory__image-wrapper--border: 'none';
$app-memory__image-wrapper--box-shadow: 'none';
$app-memory__image-wrapper--border-radius: var(--activities__image--radius);
$app-memory__button-image__currently-correct--background: 'var(--pristine)';
$app-memory__available-answers__hide--background: 'var(--pristine)';
$app-memory__available-answers--border-radius--color: #207a79;
$app-memory__card-info__image-wrapper--background: var(--Gris_bouton);

/*****************************APP-AWARNESS*****************************************/
$app-awarness__without__sound__change-title-position: false;
$memory__card__back: var(--milan-vert);

/* ******************************* COLLECTION ****************************************/
$collection__link--color: $secondary-c;
$collection__link--font-weight: 700;