// -------- DEFAULT -----------
// @import "../default/core";
/*
 *  Refactoring fusion des éléments de default dans le theme de l'instance
 *
 */
// ici on garde les css default lorsqu'ils n'ont pas été fusionnés
@import "../abstracts/path-function";
// @import "../default/variables";
@import "../../default/helper";

// ici on commence la fusion des éléments de Core
// @import "../default/core/core";
@import '../../default/core/accordion';
@import '../../default/core/badges';
@import '../../default/core/basic-page';

// @import '../default/core/_button';

@import '../../default/core/cards';
@import '../../default/core/chips';
@import '../../default/core/ck-editor';
@import '../../default/core/colors';
@import '../../default/core/dash-widget';
@import '../../default/core/datepicker';
@import '../../default/core/dialog';
@import '../../default/core/editor';
@import '../../default/core/filters';
@import '../../default/core/form-field';
@import '../../default/core/fxflex';
@import '../../default/core/grid';
@import '../../default/core/header';
@import '../../default/core/list';
@import '../../default/core/mat-icon';
@import '../../default/core/mat-tab';
@import '../../default/core/navigation';
@import '../../default/core/paginator';
@import '../../default/core/pastille';
@import '../../default/core/snackbar'; // LMS
@import '../../default/core/spinner'; // LMS
@import '../../default/core/table';
@import '../../default/core/toolbar';
// @import '../default/core/_typography';
@import '../../default/core/navbar-vertical.component';
@import '../../default/core/video'; // LMS