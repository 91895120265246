app-home-page {

    .container-banner-home-header {
        margin: 20px 0;
        width: calc(100% - 60px);
        border-radius: 5px;
        position: relative;
    }

    .banner {
        position: relative;
        height: 250px;
        margin: 20px 0;
        border-radius: 5px;
        max-height: 160px;
        height: 160px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;

            &.logo-inside-banner {
                width: 140px;
                position: absolute;
                bottom: 20px;
                right: 20px;
                height: auto;
            }
        }

        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .home-header {
        width: 100%;
        margin: 20px 0;
        position: absolute;
        top: 0;
        max-height: 160px;
        height: 160px;
        color: white;
        background: linear-gradient(90deg, #323A49 41.15%, rgba(50, 58, 73, 0.83) 66.34%);
        border-radius: 5px;
        padding: 5px;
    }

    .content-cards {
        width: calc(100% - 60px);
        gap: 25px 2%;
        flex-flow: row wrap;
        box-sizing: border-box;
        display: flex;
    }

    .title {
        text-decoration: none;
        position: relative;
        font-style: normal;
        letter-spacing: 1.5px;
        color: map-get($primary, 500);
        flex: 1; // 1/8
        display: flex;
        align-items: center;
        margin: 0;
        /* H1 Bold 24 */
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
    }

    .content {
        flex: 1; // 1/8
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000;

        p {
            letter-spacing: 1.5px;
            color: map-get($primary, 500);
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 15px;
        }
    }

    .mat-card {
        padding: 0;
        height: 250px;
        overflow: inherit;
        background: #FFFFFF;
        box-shadow: 0px 12px 40px -25px rgba(0, 0, 0, 0.5);
        border-radius: 5px;

        .wrapper {
            .wrapper-content {
                height: 100%;
                padding: 15px 20px;
            }
        }

        .mat-card-header {
            display: flex;
            align-items: center;
            border-radius: 5px 5px 0 0;

            .mat-card-header-text {
                margin: 0 10px;
                width: 100%;
            }

            .mat-card-title {
                margin: 0;
                color: #fff;
                font-size: 19px;
                line-height: 20px;
                font-weight: 700;
                width: 100%;

                .image-title {
                    width: 53px;
                    height: 53px;
                    background-color: white;
                    border: 2px solid #323A49;
                    border-radius: 5px;
                    position: absolute;
                    margin-top: -10px;
                    background-size: 100% auto;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                }

                h2 {
                    padding-left: 70px;

                    &.no-image {
                        padding-left: 10px;
                    }
                }
            }
        }

        .mat-card-img {
            color: #fff;
            width: 140px;
            min-width: 140px;

            img {
                margin-bottom: 0;
                width: 140px;
                height: 140px;
                max-width: 140px;
                max-height: 140px;
            }
        }

        .mat-card-content {
            flex: 1 1 auto !important;
            overflow: auto;
            margin-bottom: 0;
            height: inherit;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;

            dl {
                margin: 0;

                ul {
                    margin: 0;
                    padding: 0 10px;
                    list-style-position: inside;
                }
            }
        }

        .mat-card-actions {
            margin: 0;
            padding: 0;
            flex: 0 0 auto !important;

            button {
                border-radius: 16px;
                padding: 0 36px;
            }

        }
    }
}