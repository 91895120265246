/* ----- abstracts ----- */
@mixin sizeFix($width, $height: $width) {
    width: $width;
    height: $height;
    min-width: $width;
    min-height: $height;
}

// Transforme nos boutons raised en mat-fab de taille 32px
%mat-fab-32 {
    border-radius: 50%;
    @include sizeFix(32px);
}

default, .invisible {
  display: none;
}
